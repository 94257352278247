import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const options = {
    shouldForwardProp: (prop) => prop !== 'gradientColors',
};

const GradientButton = styled(
    Button,
    options,
)(({ theme, gradientColors }) => ({
    border: '5px solid',
    borderImageSlice: 1,
    borderImageSource: `linear-gradient(to left, ${gradientColors.join(',')})`,
}));

const GradientModal = styled(
    Modal,
    options,
)(({ theme, gradientColors }) => ({
    border: '5px solid',
    borderImageSlice: 1,
    borderImageSource: `linear-gradient(to left, ${gradientColors.join(',')})`,
}));

const GradientBox = styled(
    Box,
    options,
)(({ theme, gradientColors }) => ({
    minHeight: '200px',
    maxHeight: '400px',

    border: "double 1em transparent",
    borderRadius: "35px",
    backgroundImage: `linear-gradient(45deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 85%)`,
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    // linear-gradient(to right, green, gold);

    // border: '5px solid',
    // borderImageSlice: 1,
    // borderImageSource:
    //     `linear-gradient(45deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 85%)`,
    // `linear-gradient(to left, ${gradientColors.join(',')})`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: '550px',
    bgcolor: 'background.paper',
    // borderImageSlice: 1,
    // borderImageSource: `linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%)`,
    // border: '2px solid #000',
    // borderColor: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    // borderRadius: '20px',
    boxShadow: 24,
    p: 4,
}));
const remixColors = ["rgb(64, 94, 230)", "rgb(255, 82, 82)"]// ['#FE6B8B', '#FF8E53']
const gradient_style = {
    // position: 'absolute',
    // top: '25%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: '90%',
    // maxWidth: '500px',
    padding: "8px",
    width: "100%",
    bgcolor: 'background.paper',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    // borderImageSlice: 1,
    // borderImageSource: `linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%)`,
    // border: '2px solid #000',
    // borderColor: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    // borderRadius: '20px',
    // boxShadow: 24,
    // p: 4,
};

export {
    GradientButton,
    GradientModal,
    GradientBox,
    remixColors,
    gradient_style,
}