import React from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import FabBar from './fabBar'

const SplitScreen = ({ children, top_min_height = "50%", top_height = "70%", ...fab_props }) => {

    let top, bottom
    if (children.length > 1) {
        [top, bottom] = children
    }
    else
        top = children[0]

    return <Box sx={{
        maxWidth: "md",
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        position: "relative",
    }}>
        {/* <FabBar
            position={"fixed"}
            top={"auto"}
            right={5}
            bottom={8}
            left={"auto"}
            // sx={{ position: 'fixed', bottom: 0, left: 0, }}
            elevation={3}
            {...fab_props}
        // actions={layers}
        // currentLayer={currentLayer}
        /> */}
        <Stack
            direction="column"
            spacing={0}
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",// "flex-start",
                justifyContent: "center",
                flex: 1,
            }}>
            <Box
                sx={{
                    // background: "#ABC",
                    width: "100%",
                    minHeight: top_min_height,
                    maxHeight: top_height,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >{top}</Box>
            <Box
                sx={{
                    // background: "#CBA" ,
                    width: "100%",
                    maxHeight: "50%",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1
                }}
            >{bottom}</Box>
        </Stack>

        {/* <Box sx={{
            flexGrow: 1,
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center"
        }}>
            {children}
        </Box> */}
    </Box>
}

export default SplitScreen