import { styled } from '@mui/system';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';

const StyledContainer = styled(Container)({
    paddingLeft: "6px",
    paddingRight: "6px",
    // spacing: 1,
    // marginLeft: 0,
    // marginLeft: "auto",
});

const StyledCard = styled(Card)({
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // elevation: 5,
    maxWidth: 512,
    margin: 6,
    border: `2px solid rgba(0, 76, 153, .6)`,
    borderRadius: 10,
    width: "100%",
    backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"

    // padding: 10,
    // borderThickness: 2,
    // borderColor: "rgb(0, 76, 153)",
    // backgroundColor: "rgba(0, 58, 117, 0.2)"
});

const StyledAppCard = styled(Card)({
    margin: 0,
    elevation: 10,
    border: `3px solid rgba(160, 88, 156, .6)`,
    // rgb(64, 94, 230) 0%, rgb(255, 82, 82)
    borderRadius: 7,
    textAlign: "center",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
})

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    // marginLeft: 'auto',
    marginRight: 'auto',
    // display: 'flex',
    // alignItems: 'flex-start',
    // justifyContent: 'center',
    // textAlign: "left",
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledCardHeader = styled((props) => {
    return <CardHeader {...props} titleTypographyProps={{
        variant: 'h2',
        style: { fontSize: 25, fontWeight: 600 }
    }} />
})({
    color: "rgba(0, 95, 224, .8)",
    padding: 2,
})

export {
    StyledContainer,
    StyledCard,
    StyledCardHeader,
    StyledAppCard,
    ExpandMore
}