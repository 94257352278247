import React from 'react'
import { StyledCard } from '../styled/cards'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ForkRight from '@mui/icons-material/ForkRight';
import CircularProgress from '@mui/material/CircularProgress';
import copy from 'copy-to-clipboard';
import Tooltip from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'


const EmbedActions = ({
    onRemix
}) => {
    const [isRemixing, setIsRemixing] = React.useState(false)
    const [copyShow, setCopyShow] = React.useState(false)
    const url = window.location.href
    return (
        <StyledCard sx={{
        }} >
            <Box sx={{
                display: "flex",
                justifyContent: "flex-end",
                textAlign: "center",
                alignItems: "center",
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center', justifyContent: "center",
                    marginLeft: 1
                    // margin: 1
                }}>
                    <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Copied"
                        onClose={() => { setCopyShow(false) }}
                        open={copyShow}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                    >
                        <Button
                            variant="primary"
                            style={{ margin: '.33em' }}
                            onClick={() => {
                                if (copy(url))
                                    setCopyShow(true)
                            }}>
                            Share</Button>
                    </Tooltip>


                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button
                            sx={{ color: "#fff" }}
                            size="small"
                            className="remix-button"
                            variant="contained"
                            disabled={isRemixing}
                            onClick={() => { onRemix(setIsRemixing) }}
                            startIcon={<ForkRight />}>
                            {isRemixing ? "Remixing..." : "Remix"}
                        </Button>
                        {isRemixing && <CircularProgress
                            size={28}
                            sx={{
                                // color: green[500],
                                position: 'absolute',
                                // top: -16,
                                // top: -14,
                                // left: -14,
                                left: -32,
                                zIndex: 1,
                            }} />
                        }
                    </Box>


                </Box>
            </Box>
        </StyledCard>
    )
}

export default EmbedActions