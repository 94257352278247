import React from 'react'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import TextField from '@mui/material/TextField';
import { CssTextField } from "../../styled/text"

import { useSnapshot, proxy } from 'valtio'
import { getTextProxy, getSessionDataProxy, getAuthData } from "../../../store/artifact"
import SplitScreen from "../splitScreen"
import CircularProgress from '@mui/material/CircularProgress';
import { getLayerImage, getImageUrl } from "../load"
const TextOverlayEditorContent = ({ stateKey, text, state, label, triggerEdit, setAnyChanges }) => {
    // const [innerText, setInnerText] = React.useState(text || "")
    // console.log("Redraw overlawy: ", text, " st", state, "key", stateKey)
    return <CssTextField
        id="multiline-static"
        label={label}
        // disabled={!isEditing}
        defaultValue={text[stateKey]}
        onChange={(e) => {
            // console.log("Text seen")
            if (e.target.value != state.text) {
                // console.log("Text changed, set ay changes true")
                setAnyChanges(true)
            }
            // setInnerText(e.target.value)
            // state.text = e.target.value || ""
            state[stateKey] = e.target.value || ""
        }}
        InputProps={{
            onClick: () => { triggerEdit() }
        }}
        InputLabelProps={{
            onClick: () => { triggerEdit() }
        }}
        fullWidth
        multiline
    />
}

const TextOverlayListItem = ({
    list_title, ...props }) => {
    return <ListItem style={{
        display: "flex", alignItems: "center", justifyContent: "center"
    }} >
        <TextOverlayEditorContent
            {...props}
            label={list_title} />
    </ListItem>
}


const TextOverlay = ({ orig_data, layer_data, onSaveData, onDiscardData, setAnyChanges }) => {
    // console.log("text data:", layer_data)
    const proxy_params = layer_data.layer.layer_parameters.parameters
    const snap_layer = useSnapshot(layer_data.layer)
    const snap_params = useSnapshot(proxy_params)
    const orig_params = orig_data.layer.layer_parameters.parameters

    const img_uuid = layer_data.layer.uid
    // get our proxy object
    const img_uuid_session = getSessionDataProxy(img_uuid)

    // get the reactive version that will change if anything in proxy changes anywhere else in app
    const img_snap_session = useSnapshot(img_uuid_session)

    // now let's get the data and make sure it's added to the canvas 
    React.useEffect(() => {
        // let's get the background image
        if (layer_data.layer.uid != null) {
            getLayerImage({
                uuid: img_uuid,
                layer_type: layer_data.layer.layer_type,
                setError: (err) => { console.log("Error getting layer image", err) },
                snap_preview: img_snap_session,
                proxy_preview: img_uuid_session,
            }).then(() => {
                // console.log("fin")
            }).catch((err) => {
                console.log("Error getting layer image", err)
            })
        }
        // else {
        //     // otherwise, we are local object for now,
        //     // let's set direct from data
        //     const limg_src = layer_data.layer.layer_parameters.parameters.img_src
        //     console.log("Local image:", limg_src.substring(0, 100))
        //     img_uuid_session.previewImage = limg_src
        // }
    }, [img_snap_session.previewUrl])

    React.useEffect(() => {
        // when layer data changes, updated previewUrl
        let api_img = getImageUrl({
            auth: getAuthData(),
            uuid: img_uuid,
            layer_type: layer_data.layer.layer_type
        })
        img_uuid_session.previewUrl = api_img
    }, [layer_data])


    React.useEffect(() => {
        // console.log("Image data changed:", image_data)
        if (img_snap_session.previewImage != null) {
            // set it inside of our image preview plz
        }
    }, [img_snap_session.previewImage])


    // const textProxy = getTextProxy(layer_data.layer.uid)

    // const topState = proxy({ text: snap_params.top_text })
    // const bottomState = proxy({ text: snap_params.bottom_text })

    // onDiscardData(() => {
    //     topState.text = snap_params.top_text
    //     bottomState.text = snap_params.bottom_text
    // })
    onSaveData(async () => {
        // update our info plz 
        console.log("Saving text data")
        let data_diff = false
        if (proxy_params.top_text != orig_params.top_text) {
            data_diff = true
            // proxy_params.top_text = orig_params.top_text
        }
        if (proxy_params.bottom_text != orig_params.bottom_text) {
            data_diff = true
            // proxy_params.bottom_text = orig_params.bottom_text
        }
        return data_diff
    })
    return <SplitScreen top_min_height="30%" top_height="50%">
        {img_snap_session.previewImage == null ?
            <CircularProgress /> :
            <img
                className={"fleximg"}
                src={img_snap_session.previewImage}
                alt="meme"
                onClick={() => { }}
                style={{
                }} />
        }

        <Box sx={{
            width: '100%',
            height: '100%',
            mt: 1,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
        }}>
            <List sx={{}} >
                <TextOverlayListItem list_title="Top Text"
                    setAnyChanges={setAnyChanges}
                    stateKey="top_text"
                    text={snap_params}
                    state={proxy_params}
                    triggerEdit={() => { }} />
                <TextOverlayListItem list_title="Bottom Text"
                    setAnyChanges={setAnyChanges}
                    stateKey="bottom_text"
                    text={snap_params}
                    state={proxy_params}
                    triggerEdit={() => { }} />
            </List>
        </Box>
    </SplitScreen>

}

export default TextOverlay
