import React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Check from '@mui/icons-material/Check';
import Cancel from '@mui/icons-material/Cancel';
import Undo from '@mui/icons-material/Undo';
import Redo from '@mui/icons-material/Redo';
import NavigationIcon from '@mui/icons-material/Navigation';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import tinygradient from 'tinygradient';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import Forward from '@mui/icons-material/Forward';
import { useSnapshot } from 'valtio'
import { editLayerData } from '../../store/artifact'
// import Box from '@mui/material/Box';

const BFab = styled(Fab)({
    background: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255,1))",
    backgroundAttachment: "fixed",
    backgroundPosition: "center center",
    backgroundSize: "50% 100%" /* make a "best guess" here as a default */
})

const SFab = styled(Fab)({
    display: "inline-flex",
    flexGrow: "0",
    fontSize: "15px",

    // fontWeight: "500",
    // lineHeight: "18px",
    // borderRadius: "50px",
    // height: "28px",
    minWidth: "25px",
    width: "auto",
    height: "auto"
    // padding: "0 16px",
})

const getFabClass = (currentLayer, index) => {
    if (currentLayer == index)
        return "fab_active"
    if (currentLayer > index)
        return "fab_previous"
    return "fab_next"
}
const getFabColor = (currentLayer, index) => {
    if (currentLayer == index)
        return "primary"
    if (currentLayer > index)
        return "success"
    return "title"
}


const FabItem = ({ layer, index, currentLayer, saveAndProceed }) => {
    // console.log("Layer anem: ", "fab_box_" + layer.name + "_" + index)
    return <Box sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-around",
        alignItems: "center",
        p: 0,
        m: 0
    }}
        key={"fab_box_" + layer.name + "_" + index}                >
        <Tooltip
            key={"tool_" + layer.name + "_" + index}
            title={layer.tooltip} position="top">
            <Fab
                color={getFabColor(currentLayer, index)}
                size="small"
                className={getFabClass(currentLayer, index)}
                aria-label={layer.name} key={"fab_" + layer.name + index}
                onClick={() => { saveAndProceed(layer.name) }} >
                {currentLayer === index ? layer.select_icon() : layer.icon()}
            </Fab >
        </Tooltip>
        <Forward
            key={"fwd_" + layer.name + "_" + index}
            sx={{ ml: "3px", fontSize: "15px", color: "#A3A3A3A3" }} />
    </Box>
}

const FabSteps = ({
    // layers, currentLayer,
    saveAndProceed,
    discardChanges,
    anyChanges,
    position, bottom, left, right, top }) => {

    let { layers, currentLayer } = useSnapshot(editLayerData)

    const theme = useTheme();
    return (layers.length == 0 ? null :
        <Box sx={{
            display: 'flex',
            direction: "column",
            width: '95%',
            justifyContent: 'space-between',
            // background: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255,1))",
            // backgroundAttachment: "fixed",
            // backgroundPosition: "0px center",
            // backgroundSize: "100% 20%",
            zIndex: 1000,
            alignItems: 'center',
            // ml: 1,
            // mr: -1,
            // m: "5px",
            // mr: 1,
            // sx={{ position: 'fixed', bottom: 0, left: 0, }}
            // margin: 0,
            // top: 'auto',
            // right: 20,
            // bottom: 20,
            // left: 'auto',
            // position: 'fixed',
            position,
            bottom,
            left: "2.5%",
            // left,
            // right,
            top,

            // '& > :not(style)': {
            //     m: 1,
            // }
        }}>
            <Fab
                // position="fixed"
                // bottom={0}
                // left={0}
                // right={"auto"}
                // top={"auto"}
                sx={{ visibility: anyChanges ? "visible" : "hidden" }}
                aria-label="next"
                color="primary"
                size={"small"}
                className={""}
                key="fab_next"
                onClick={() => { discardChanges() }}
            >
                <Cancel sx={{ color: "#FFF" }} />
            </Fab>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-around",
                alignItems: "center",
            }} >

                {layers.map((layer, index) => {
                    return <FabItem key={"layer_ix_" + index}
                        saveAndProceed={saveAndProceed}
                        layer={layer}
                        currentLayer={currentLayer} index={index} />
                })}
                {/* <BFab
                color="primary"
                aria-label="add">
                <AddIcon />
            </BFab>
            <BFab
                color="secondary"
                aria-label="edit">
                <EditIcon />
            </BFab> */}
                {/* <Box sx={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                direction: "column",
                height: "100%",
                // width: "100%",
                // width: "auto",
                '& > :not(style)': {
                    m: 0,
                }
                // position: "fixed",
            }}>
                <Box sx={{
                    position: "absolute", left: -15, top: 0, zindex: 1000,
                    display: "flex", alignItems: "center", justifyContent: "center", direction: "column",
                }}>
                    <SFab aria-label="undo"
                        variant="circular"
                    // sx={{
                    //     width: `${redo_undo_size.width}px`,
                    //     height: "auto"
                    // }}
                    // size="small"
                    >
                        <Undo sx={{
                            fontSize: "15px"
                        }} />
                    </SFab>
                </Box>
                <Box sx={{
                    position: "absolute", left: -15, bottom: 0, zindex: 1000,
                    display: "flex", alignItems: "center", justifyContent: "center", direction: "column",
                }}>
                    <SFab aria-label="redo"
                        // sx={{ width: `${redo_undo_size.width}px`, height: `${redo_undo_size.height}px` }}
                        size="small">
                        <Redo
                            sx={{
                                fontSize: "15px"
                            }} />
                    </SFab>
                </Box>
            </Box> */}

                <Fab
                    aria-label="next"
                    color="success"
                    className={currentLayer == layers.length ? "fab_active" : ""}
                    key="fab_next"
                    onClick={() => { saveAndProceed() }}
                >
                    <Check sx={{ color: "#FFF" }} />
                </Fab>
            </Box>
        </Box >
    );
}


export default function FabBar({ ...props }) {
    return <FabSteps {...props} />


}