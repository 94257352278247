import React from 'react'
import Box from '@mui/material/Box';
const TitleBar = () => {
    return (
        <Box sx={{
            width: '100%',
        }}>
            <div className='titlebar px-3 py-2'>
                <h1 className='imdisco'>imDisco</h1>
                <h6 className='tagline'>Make &rarr; Share &rarr; Remix</h6>
            </div>
        </Box>)
}

export default TitleBar