import React from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';

const SmallPadContainer = styled(Container)({
    paddingLeft: "6px",
    paddingRight: "6px",
});

const FullContainer = styled(Box)({
    height: '100%',
    width: '100%',
    padding: 0,
    margin: 0,
})
const CenterBox = styled(Box)({
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
})

const FullStackStart = styled(Stack)({
    direction: 'column',
    // justifyContent: 'space-between',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    width: '100%',
})

const FullStackBetween = styled(Stack)({
    direction: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    height: '100%',
    width: '100%',
})
// const FullContainer = ({ children }) => {
//     return <FullBox sx={{ padding: 0, margin: 0 }} >
//         {children}
//     </FullBox>
// }

export {
    FullContainer,
    FullStackStart,
    FullStackBetween,
    SmallPadContainer,
    CenterBox
}