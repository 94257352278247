const _ = require('lodash')
const meme_url = 'https://i.imdisco.com/meme'

const getBaseUrl = () => {
    const url_checks = ['/meme', '/remix', '/preview']
    for (let i = 0; i < url_checks.length; i++) {
        if (window.location.href.includes(url_checks[i])) {
            return window.location.href.split(url_checks[i])[0]
        }
    }
    return window.location.href
}

const isDev = () => {
    return window.location.href.includes("localhost") || !window.location.href.includes("imdisco.com")
}

const getMemeData = () => {
    return (window && window.__IMDISCO_META__) || null;
}

const setMemeData = (data) => {
    window.__IMDISCO_META__ = data
}

const getMemeAuthor = () => {
    return (window && window.__IMDISCO_AUTHOR__) || null;
}
const setMemeAuthor = (data) => {
    window.__IMDISCO_AUTHOR__ = data
}

const getAuthData = () => {
    // first check if we have local storage
    let auth = window.localStorage.getItem("imDiscoAuth");
    if (auth) { auth = JSON.parse(auth) }
    if (_.isEmpty(auth)) {
        auth = null
    }
    if (!auth) {
        // check local
        auth = window.__IMDISCO_AUTH__
        // we had imdisco auth by not local auth
        // save our auth to local storage
        if (auth && auth.access_token)
            window.localStorage.setItem("imDiscoAuth", JSON.stringify(auth));
    }
    if (_.isEmpty(auth) || !auth || !auth.access_token) {
        return undefined
    }
    return auth
}
const clearAuthData = () => {
    window.localStorage.removeItem("imDiscoAuth");
}

const getMemeUrl = ({ meme_id, user_id, isPreviewEditing }) => {
    const base_url = getBaseUrl()
    // const auth = getAuthData()
    return isPreviewEditing ? `${base_url}/preview/${meme_id}/${user_id}`
        : `${base_url}/meme/${meme_id}`
}

const getPreviewEditUrl = ({ meme_id, user_id }) => {
    const base_url = getBaseUrl()
    return `${base_url}/preview/${meme_id}/${user_id}/edit`
}
const getPreviewPublishUrl = ({ meme_id, user_id }) => {
    const base_url = getBaseUrl()
    return `${base_url}/preview/${meme_id}/${user_id}/publish`
}
const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

const getArtifactBucket = () => {
    return isDev() ? 'DEV_ARTIFACT_BUCKET' : 'ARTIFACT_BUCKET'
}

export {
    // meme_url,
    getMemeUrl,
    getRandomInt,
    getPreviewEditUrl,
    getPreviewPublishUrl,
    getBaseUrl,
    isDev,
    getArtifactBucket,
    getMemeData,
    setMemeData,
    getMemeAuthor,
    setMemeAuthor,
    getAuthData,
    clearAuthData
}