import { getMemeData } from "../utils"
import { proxy, useSnapshot } from 'valtio'
import { getAuthData as utilAuthData } from "../utils"
import _ from "lodash"

// const state = proxy({ count: 0, text: 'hello' })

// import { temporal } from 'zundo'
// import { create } from 'zustand'

// // creates a store with undo/redo capability
// const useStoreWithUndo = create()(
//     temporal((set) => ({
//         bears: 0,
//         increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
//         removeAllBears: () => set({ bears: 0 }),
//     })),
// );

// const useTemporalStore = create(useStoreWithUndo.temporal)

// let currentMeme

// const getCurrentMeme = () => {
//     if (!currentMeme) {
//         const meme = getMemeData()
//         if (meme != null) {
//             currentMeme = proxy(meme)
//         }
//     }
//     return currentMeme
// }

const layerUrl = proxy({
    currentLayerName: null,
    gotoLayerName: null
})
const editLayerData = proxy({
    currentLayer: 0,
    anyChanges: false,
    layers: []
})
const origLayerData = proxy({
    layers: []
})

const previewData = proxy({
    previewMemeImage: null,
    previewMemeUrl: null,
    previewMemeCurrentUrl: null,
    previewLayerImage: null,
    previewLayerCurrentUrl: null,
    previewLayerUrl: null,
})

const authData = proxy({
    user: null,
})

const getAuthData = () => {
    if (authData.user == null) {
        // attempt to fetch
        authData.user = utilAuthData()
    }
    return authData.user
}

const data_proxy = {}
const getSessionDataProxy = (uuid) => {
    if (data_proxy[uuid] == null) {
        data_proxy[uuid] = proxy({
            previewUrl: null,
            previewCurrentUrl: null,
            previewImage: null,
            imageQuery: null,
        })
    }
    return data_proxy[uuid]
}
const text_proxy = {}
const getTextProxy = (uuid) => {
    if (text_proxy[uuid] == null) {
        text_proxy[uuid] = proxy({
            top_text: { text: null },
            bottom_text: { text: null }
        })
    }
    return text_proxy[uuid]
}


const art_cache = {}
const getArtifactCache = (img_url) => {
    return art_cache[img_url]
}
const setArtifactCache = (img_url, img) => {
    art_cache[img_url] = img
}

const createCancellableRequest = async ({ ...fetch_params }) => {
    const controller = new AbortController();
    const { signal } = controller;
    // const request = new Request(fetch_params.url, {
}

const currentMeme = { meme: {} }
const clearImageProxies = () => {
    _.each(Object.keys(art_cache), (key) => {
        art_cache[key] = null
    })

    _.each(Object.values(data_proxy), (val) => {
        val.previewUrl = null
        val.previewCurrentUrl = null
        val.previewImage = null
        val.imageQuery = null
    })
}
export {
    getArtifactCache,
    setArtifactCache,
    clearImageProxies,
    // getCurrentMeme,
    currentMeme,
    origLayerData,
    editLayerData,
    previewData,
    authData,
    layerUrl,
    getAuthData,
    getTextProxy,
    getSessionDataProxy,
    createCancellableRequest
}





