

const CREATE_SESSION = ({ url, user_id }) => { return `${url}/api/session/${user_id}/create` }
const EDIT_SESSION = ({ url, user_id, session_id }) => { return `${url}/api/session/${user_id}/${session_id}/edit` }
const PUBLISH_SESSION = ({ url, user_id, session_id }) => { return `${url}/api/session/${user_id}/${session_id}/publish` }
const REMIX_SESSION = ({ url, user_id, session_id }) => { return `${url}/api/session/${user_id}/${session_id}/remix` }
const SESSION_DATA = ({ url, user_id, session_id, layer_type, artifact, bucket }) => {
    let to_ret = `${url}/api/session/${user_id}/${layer_type}/${artifact}/${session_id}/fetch`
    if (bucket)
        to_ret += `?bucket=${bucket}`
    return to_ret
}
const SESSION_MEM_DATA = ({ url, user_id, session_id, layer_type, artifact }) => {
    return `${url}/api/session/${user_id}/${layer_type}/${artifact}/${session_id}/memory`
}

const getBaseUrl = () => {
    return window.location.protocol + '//' + window.location.host
}

const isDev = () => {
    return getBaseUrl().includes("localhost") || !getBaseUrl().includes("imdisco.com")
}
const getArtifactBucket = () => {
    return isDev() ? 'DEV_ARTIFACT_BUCKET' : 'ARTIFACT_BUCKET'
}

const getAPIPath = ({ path, no_cache, ...params }) => {
    let api_path = path({ url: getBaseUrl(), ...params })
    if (no_cache) {
        if (api_path.includes("?"))
            api_path += "&"
        else
            api_path += "?"
        api_path += "ds=" + Date.now()
    }
    return api_path
}

const getInitRequest = ({ method, auth }) => {
    return {
        method,
        headers: {
            'content-type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${auth.access_token}`,
        },
    }
}

export {
    getBaseUrl, getAPIPath, getInitRequest, getArtifactBucket,
    CREATE_SESSION, EDIT_SESSION, PUBLISH_SESSION, REMIX_SESSION, SESSION_DATA, SESSION_MEM_DATA
}