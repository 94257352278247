import React from 'react'
import Box from '@mui/material/Box';
import SplitScreen from "../splitScreen"
import { useSnapshot, proxy } from 'valtio'
import { getTextProxy, getSessionDataProxy, getAuthData, currentMeme } from "../../../store/artifact"
import CircularProgress from '@mui/material/CircularProgress';
import { getLayerImage, getImageUrl } from "../load"
import Typography from '@mui/material/Typography';

const Publish = ({ layer_data, onSaveData }) => {

    const [show, setShow] = React.useState(false)

    const handleClose = () => {
        setShow(false)
    }

    onSaveData(async () => {
        // update our info plz 

    })
    console.log("layer data:", layer_data, "cmeme:", currentMeme.meme)
    // const proxy_params = layer_data.layer.layer_parameters.parameters
    // const snap_layer = useSnapshot(layer_data.layer)
    // const snap_params = useSnapshot(proxy_params)
    // const orig_params = orig_data.layer.layer_parameters.parameters

    const img_uuid = currentMeme.meme.uid
    // // get our proxy object
    const img_uuid_session = getSessionDataProxy(img_uuid)

    // // get the reactive version that will change if anything in proxy changes anywhere else in app
    const img_snap_session = useSnapshot(img_uuid_session)
    React.useEffect(() => {
        // let's get the background image
        if (currentMeme.meme.uid != null) {
            getLayerImage({
                uuid: img_uuid,
                layer_type: "preview_meme",
                setError: (err) => { console.log("Error getting layer image", err) },
                snap_preview: img_snap_session,
                proxy_preview: img_uuid_session,
            }).then(() => {
                // console.log("fin")
            }).catch((err) => {
                console.log("Error getting layer image", err)
            })
        }
    }, [img_snap_session.previewUrl])

    React.useEffect(() => {
        // when layer data changes, updated previewUrl
        let api_img = getImageUrl({
            auth: getAuthData(),
            uuid: img_uuid,
            layer_type: "preview_meme"
        })
        img_uuid_session.previewUrl = api_img
    }, [layer_data])

    let title = currentMeme.meme.title
    if (title == null || title == "") {
        title = "Untitled"
    }
    return <SplitScreen>
        {img_snap_session.previewImage == null ?
            <CircularProgress /> :
            <img
                className={"fleximg"}
                src={img_snap_session.previewImage}
                alt="meme"
                onClick={() => { }}
                style={{
                }} />
        }

        <Box sx={{
            width: "100%", height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",

        }}>

            <Typography variant="h6" component="div">
                {/* Meme Title: {title} */}
                Meme Final Preview
            </Typography>

            {/* <div>
                Publish form

            </div> */}

        </Box>
    </SplitScreen>
}

export default Publish