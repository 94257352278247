import React from 'react'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import { CssTextField } from "../../styled/text"

import Slider from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';

import Close from '@mui/icons-material/Close';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { isMobile, browserName } from "react-device-detect";
import { useSnapshot } from 'valtio';
import { getArtifactCache, setArtifactCache, getAuthData, getSessionDataProxy } from '../../../store/artifact'
import { getAPIPath, SESSION_DATA, getInitRequest, getArtifactBucket } from "../../../api"
import { loadArtifact, pasteToImage, createPasteEvent, convertFileToBase64, getLayerImage } from "../load"


const ImagePreview = ({
    setErrorMsg,
    img_uuid_session,
    isLoading,
    snap_params,
    proxy_params,
    onSaveData, setAnyChanges,
    strength, setStrength,
    imageData, setImageData }) => {
    // console.log("Proxy params:", proxy_params)
    const onImageData = (data) => {
        setAnyChanges(true)
        if (data) {
            setImageData(data)
            proxy_params.img_src = data.replace(/^data:image\/[a-z]+;base64,/, "")
        }
        else {
            proxy_params.img_hash = null
            proxy_params.img_src = null
        }
    }
    const onPaste = (event) => {
        pasteToImage({
            event,
            onImageData
        })
    }


    return isLoading ?
        <CircularProgress color="white" />
        : <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", alignItems: "center",
            width: "100%",
        }}>
            {img_uuid_session.previewImage &&
                <Stack>
                    <Box sx={{
                        position: "relative",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}>
                        <img src={img_uuid_session.previewImage}
                            alt="meme"
                            // maxHeight="512px"
                            className={"fleximg"}
                            onClick={() => { }}
                            style={{
                                maxHeight: "70%",
                                maxWidth: "60%",
                                height: "auto"
                            }} />
                        <Box sx={{
                            position: "absolute",
                            top: 0,
                            right: "5%",
                            left: "auto",
                            bottom: "auto",
                            zIndex: 1
                        }}>
                            <Fab key="delete_img" size="small"
                                onClick={() => {
                                    // setAnyChanges(true)
                                    onImageData(null)
                                    // setImageData(null)
                                }}><Close /></Fab>
                        </Box>
                    </Box>
                </Stack>
            }
            {img_uuid_session.previewImage && <Button
                variant="contained"
                onClick={() => {
                    // setAnyChanges(true)
                    onImageData(null)
                    // setImageData(null)
                }}
            >Replace Image</Button>}
            {!img_uuid_session.previewImage && <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                {!isMobile ? <CssTextField
                    // focused={focus}
                    inputProps={{ accept: "*", onPaste: onPaste, padding: 0, margin: 0 }} size="small" defaultValue={""} label="Paste Image Here" />
                    : <Button onClick={() => {
                        createPasteEvent(onImageData, setErrorMsg)
                    }}>Paste Here</Button>}
                <Typography variant="caption" sx={{ textAlign: "center" }}>- OR -</Typography>
                <Button variant="outlined" startIcon={<PhotoCamera />} component="label">
                    Upload Image
                    <input hidden accept="image/*" type="file" onChange={(e) => {
                        // console.log("Updload file", e)
                        if (e.target.files && e.target.files.length > 0) {
                            convertFileToBase64({ file: e.target.files[0], onImageData })
                        }
                    }} />
                </Button>
            </Box>}
        </Box >
}
// const loadArtifact = async ({ api_url, request, is_json = true }) => {
//     console.log("Requesting: ", api_url)
//     let res = getArtifactCache(api_url)
//     if (res != null)
//         return res

//     res = await fetch(api_url, request)
//     if (!res.ok) throw new Error(res.statusText)
//     // body to text 
//     // return await res.body.getReader().read()
//     // // console.log("Response", await res.text())
//     let to_ret
//     if (is_json)
//         to_ret = await res.json()
//     else
//         to_ret = await res.text()
//     setArtifactCache(api_url, to_ret)
//     return to_ret
// }

const Foreground = ({ layer_data,
    setErrorMsg,
    onSaveData,
    setAnyChanges }) => {

    const proxy_params = layer_data.layer.layer_parameters.parameters
    const snap_layer = useSnapshot(layer_data.layer)
    const snap_params = useSnapshot(proxy_params)
    const snap_auth = useSnapshot(getAuthData())

    // console.log("Snap foreground params", snap_params)
    // console.log("Auth:", snap_auth)

    const [isLoading, setIsLoading] = React.useState(false)
    const [imageData, setImageData] = React.useState(null)
    const [foreQuery, setForeQuery] = React.useState(null)

    const img_uuid = layer_data.layer.uid
    // get our proxy object
    const img_uuid_session = getSessionDataProxy(img_uuid)
    // get the reactive version that will change if anything in proxy changes anywhere else in app
    const img_snap_session = useSnapshot(img_uuid_session)

    onSaveData(async () => {
        // we actually have replaced the image
        if (proxy_params.img_src != null) {
            delete proxy_params.img_hash
            // console.log("Proxy params:", proxy_params)
            return true
        }
        return false
    })

    React.useEffect(() => {
        // let's get the background image
        // console.log("Reset:", layer_data, "img src:", snap_params.previewImage)
        if (snap_params.img_hash != null) {
            setIsLoading(true)
            getLayerImage({
                uuid: img_uuid,
                no_cache: true,
                layer_type: layer_data.layer.layer_type,
                setError: (err) => { console.log("Error getting layer image", err) },
                snap_preview: img_snap_session,
                proxy_preview: img_uuid_session,
            }).then(() => {
                // console.log("fin")
                // console.log("Loaded image at ", img_uuid_session.previewUrl)
                // console.log("pimage:", img_uuid_session.previewImage)
                setIsLoading(false)
            }).catch((err) => {
                console.log("Error getting layer image", err)
            })
        }
        else {
            if (snap_params.img_src != null) {
                // otherwise, we are local object for now,
                // let's set direct from data
                const limg_src = snap_params.img_src
                // console.log("Local image:", limg_src.substring(0, 100))
                img_uuid_session.previewImage = "data:image/png;base64," + limg_src
            }
            else {
                img_uuid_session.previewImage = null
                img_uuid_session.previewUrl = null
                img_uuid_session.previewCurrentUrl = null
            }
        }
    }, [
        img_snap_session.previewUrl,
        snap_params.img_src,
        layer_data])

    React.useEffect(() => {
        // console.log("Image data changed:", image_data)
        if (img_snap_session.previewImage != null) {
            // set as the data of interest
            // const b64_img_src = _.isString(img_src) ? `data:image/png;base64,${img_src}` : null
            setImageData(img_snap_session.previewImage)
        }
    }, [img_snap_session.previewImage])

    // React.useEffect(() => {
    //     if (imageData == null) {
    //         console.log("Snap auth; ", snap_auth)
    //         let api_img = getAPIPath({
    //             user_id: snap_auth.id, path: SESSION_DATA,
    //             bucket: getArtifactBucket(),
    //             session_id: snap_layer.uid,
    //             artifact: "image", layer_type: "foreground"
    //         })
    //         setIsLoading(true)
    //         console.log("Loading foreground image from:", api_img)
    //         // load image data if null
    //         loadArtifact({
    //             active: foreQuery,
    //             setActive: setForeQuery,
    //             api_url: api_img,
    //             request: getInitRequest({ auth: snap_auth, method: "GET" }),
    //             is_json: false
    //         }).then((img_src) => {
    //             const b64_img_src = _.isString(img_src) ? `data:image/png;base64,${img_src}` : null
    //             console.log("Setting image src info")
    //             setIsLoading(false)
    //             setImageData(b64_img_src)
    //         }).catch((err) => {
    //             console.log("Error retrieving image", err)
    //         })
    //     }
    // }, [snap_layer.uid, layer_data])

    return <Box sx={{
        width: '100%',
        height: '100%',
        mt: 1,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    }}>
        <ImagePreview
            onSaveData={onSaveData}
            setAnyChanges={setAnyChanges}
            isLoading={isLoading}

            imageData={imageData}
            setImageData={setImageData}
            img_uuid_session={img_uuid_session}
            snap_params={snap_params}
            proxy_params={proxy_params}

            setErrorMsg={setErrorMsg}
        />
    </Box>
}

export default Foreground