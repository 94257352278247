import React from 'react'
import Box from '@mui/material/Box';
import ImageEditor from './image_editor'
import { proxy } from 'valtio'
import { editLayerData, getSessionDataProxy, getAuthData } from "../../../store/artifact"
import _ from "lodash"

const Composite = ({ layer_data, ...props }) => {
    console.log("COMPOSITE::Layer_data", layer_data)
    console.log("COMPOSITE::Props:", props)
    console.log("COMPOSITE::elayer data", editLayerData.layers)
    const layers = _.map(editLayerData.layers, (layer) => {
        return layer.layer_data
    })
    const compositeLayer = _.find(editLayerData.layers, (layer) => {
        return layer.name == "composite"
    }).layer_data

    return <ImageEditor
        layer_data={proxy({ layers })}
        compositeLayer={compositeLayer}
        {...props} />
}

export default Composite