import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CssTextField = styled(TextField)({
    // '& label': {
    //     color: 'white !important',
    // },

    // '& input': {
    //     color: 'white !important',
    // },


    // '& .MuiOutlinedInput-root': {
    //     '& fieldset': {
    //         borderColor: 'white',
    //     },
    //     '&:hover fieldset': {
    //         borderColor: 'white',
    //     },
    //     '&.Mui-focused fieldset': {
    //         borderColor: '#0582CA',
    //     },
    // }
});
export {
    CssTextField
}