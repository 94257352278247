import React from 'react';
import ReactDOM from 'react-dom/client';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import Div100vh from 'react-div-100vh';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>

  <Div100vh>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Div100vh>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// document.onpaste = function (event) {
//   console.log("Pasted: ", event)
//   var items = (event.clipboardData || event.originalEvent.clipboardData).items;
//   console.log(JSON.stringify(items)); // might give you mime types
//   for (var index in items) {
//     var item = items[index];
//     if (item.kind === 'file') {
//       var blob = item.getAsFile();
//       var reader = new FileReader();
//       reader.onload = function (event) {
//         console.log("Event result", event.target.result); // data url!
//         let zz = "" + event.target.result
//         alert("Event tgt:" + zz.substring(0, 100))
//       };
//       reader.readAsDataURL(blob);
//     }
//   }
// };