import React, { useState } from 'react'
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/system';
import { DiscordLoginButton } from "react-social-login-buttons"
import { getBaseUrl } from '../../api'
import { useParams } from 'react-router-dom'

const onClickLogin = (e, artifact, meme_id) => {
    artifact = artifact || "meme"
    e.preventDefault();
    let redirect = getBaseUrl()
    const encoded = encodeURI(redirect + '/remix');
    // let base_invite = `https://discord.com/api/oauth2/authorize?client_id=1010180238384316446&permissions=380104693824&redirect_uri=${encoded}&response_type=code&scope=identify%20bot%20applications.commands&state=${meme_id}`
    let base_invite = `https://discord.com/oauth2/authorize?client_id=1010180238384316446&redirect_uri=${encoded}&response_type=code&scope=identify&state=${artifact}__${meme_id}`
    // console.log("Base invite: ", base_invite)
    window.location.href = base_invite
}


const DiscoLoginButtons = ({ isLoggedIn, isOnServer, meme_id }) => {
    let { artifact } = useParams()
    const [loading, setLoading] = useState(false)
    if (!isLoggedIn) {
        return [
            <DiscordLoginButton
                key="login"
                style={{}}
                onClick={(e) => { onClickLogin(e, artifact, meme_id) }} />
            // <Button variant="primary" key="login" onClick={(e) => { onClickLogin(e, meme_id) }}> Login via Discord</Button>
        ]
    }
    return []
}

const remixBodyMessage = ({ isLoggedIn, isOnServer }) => {
    if (!isLoggedIn) {
        return (
            <div>
                <p>Log in to remix this image on Discord</p>
            </div>
        )
    }
    if (!isOnServer) {
        return (
            <div>
                <p>Almost there. Join the imDisco server to remix this image on Discord!</p>
            </div>
        )
    }
    return (
        <div>
            <p>Remix this image on Discord</p>
        </div>
    )
}

const gradient_style = {
    padding: "8px",
    width: "100%",
    bgcolor: 'background.paper',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};

const options = {
    shouldForwardProp: (prop) => prop !== 'gradientColors',
};
const GradientBox = styled(
    Box,
    options,
)(({ theme, gradientColors }) => ({
    minHeight: '200px',
    maxHeight: '400px',

    border: "double 1em transparent",
    borderRadius: "35px",
    backgroundImage: `linear-gradient(45deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 85%)`,
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    // linear-gradient(to right, green, gold);

    // border: '5px solid',
    // borderImageSlice: 1,
    // borderImageSource:
    //     `linear-gradient(45deg, ${gradientColors[0]} 0%, ${gradientColors[1]} 85%)`,
    // `linear-gradient(to left, ${gradientColors.join(',')})`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: '550px',
    bgcolor: 'background.paper',
    // borderImageSlice: 1,
    // borderImageSource: `linear-gradient(45deg, rgb(64, 94, 230) 0%, rgb(255, 82, 82) 85%)`,
    // border: '2px solid #000',
    // borderColor: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    // borderRadius: '20px',
    boxShadow: 24,
    p: 4,
}));


const RemixModal = ({
    show, handleClose,
    closeButton = true,
    meme_id, isLoggedIn,
    isOnServer }) => {
    // modal changes based on login

    const discoButtons = DiscoLoginButtons({
        isLoggedIn, isOnServer, meme_id
    })

    const memeBody = remixBodyMessage({ isLoggedIn, isOnServer })
    const remixColors = ["rgb(64, 94, 230)", "rgb(255, 82, 82)"]// ['#FE6B8B', '#FF8E53']
    return <Modal
        open={show}
        onClose={handleClose}
        key="remix_modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        // sx={{
        //     ":focus": {
        //         border: "none"
        //     }
        // }}

        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    // gradientColors={remixColors}
    >
        <Fade in={show}>
            <GradientBox
                className="remix_modal"
                gradientColors={remixColors}
                sx={gradient_style} >
                <Typography id="modal-modal-title"
                    style={{
                        margin: "5px",
                        fontWeight: 500,
                    }}
                    variant="h5" component="h2">
                    Meme Remix
                </Typography>
                <Typography
                    id="modal-modal-description" component="div"
                    sx={{}}>
                    {memeBody}
                </Typography>
                <Box sx={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: "10px",
                    justifyContent: "space-between", mt: 2
                }}>
                    {discoButtons.map((button) => button)}
                    {closeButton && <Button
                        // gradientColors={remixColors}
                        // variant="outlined"
                        // variant="contained"
                        style={{
                            color: "#fff",
                            height: "82%",
                            // backgroundColor: "#7389da"
                        }}
                        onClick={handleClose}>
                        Close
                    </Button>}
                </Box>
            </GradientBox>
        </Fade>
    </Modal>
}

export default RemixModal