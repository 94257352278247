import React from 'react'
// import Meme from './meme'
import { getMemeData, getMemeAuthor, getAuthData, getBaseUrl, clearAuthData } from '../../utils'
import { SmallPadContainer } from "../styled/containers"
import { StyledCard } from "../styled/cards"
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import RemixModal from "./remix"
import EmbedActions from './actions'
import _ from 'lodash'
import { getAPIPath, REMIX_SESSION } from '../../api'

const Header = ({ cap_title, meme_author }) => {
    return <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }}>
        {/* <CardHeader
            title={cap_title}
        /> */}
        < Chip
            avatar={<Avatar
                alt={meme_author.username + "#" + meme_author.discriminator}
                src={meme_author.avatar} />}
            label={"@" + meme_author.username + "#" + meme_author.discriminator}
            // variant="outlined"
            style={{ borderRadius: 0, backgroundColor: "rgba(0,0,0,0)" }}
        />
    </Box>
}

const getMemeUrl = ({ meme_id, user_id }) => {
    return `${getBaseUrl()}/meme/${meme_id}`
}




const Meme = ({
    meme_id, meme_data, meme_author,
    isPreviewEditing, }) => {
    // console.log("Auth: ", getAuthData())
    const imageRef = React.useRef(null)
    const [isUpdated, setIsUpdated] = React.useState(false)
    const meme_url = getMemeUrl({
        meme_id, user_id: meme_data.author
    })

    const meta_url = `${meme_url}/meta`
    let image_url = `${meme_url}/image`
    if (isPreviewEditing) {
        image_url += "?access_token=" + getAuthData().access_token + `&ds=${Date.now()}`
    }
    console.log("Meme:", meme_id, meme_data, meme_author)
    const detailsRef = React.useRef(null);
    const [showModal, setShowModal] = React.useState(false)
    const [isShowDetails, setIsShowDetails] = React.useState(isPreviewEditing)
    // if we have auth data, we're logged in
    const [isLoggedIn, setIsLoggedIn] = React.useState(getAuthData() ? true : false)
    const isOnServer = true
    const [errorMsg, setErrorMsg] = React.useState(null)

    const [title, setTitle] = React.useState(meme_data.title)

    // const cap_title = _.capitalize(meme_data.title)
    const cap_title = _.startCase(_.toLower(title));
    // console.log("Cap:", cap_title, meme_data.title)

    const onRemixClick = (setIsRemixing) => {
        // time to start editing 
        // we're logging in a ready to go
        onClickJoinServer({
            meme_id: meme_data.uid,
            setLoading: (val) => {
                console.log("Loading:", val)
                setIsRemixing(val)
            },
            setIsPreview: ((data) => {
                const base_url = getBaseUrl()
                let redirect = `${base_url}/preview/${data.session_id}`
                console.log("Preview created: ", data, "redirect:", redirect)
                window.location.href = redirect
            }),
            setIsUnauthorized: () => {
                clearAuthData()
                setIsLoggedIn(false)
                setShowModal(true)
            },
            setErrorMsg: (msg) => {
                setErrorMsg(msg)
            }
        })
    }

    const checkLoginRemix = (setIsRemixing) => {
        // if we're logging in, we have everything we need to start the edit session with 
        // preview information
        if (!isLoggedIn) {
            setShowModal(true)
        }
        else {
            // if (e)
            // e.preventDefault()
            // let's handle the remix
            onRemixClick(setIsRemixing)
        }
    }

    // going to have the meme, the author of the meme
    return (
        <Stack style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxHeight: "95%",
        }}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}

                autoHideDuration={5000}
                open={errorMsg ? true : false}
                onClose={() => {
                    setErrorMsg(null)
                }}
                key={"error_top_center"}
            >
                <Alert onClose={() => {
                    setErrorMsg(null)
                }} severity="error" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>

            <StyledCard className="titlecolor" sx={{
                backgroundColor: "hsla(0, 0%, 100%, .1)",
                backgroundImage: "none",
                // backgroundColor: "hsla(0, 0%, 100%, .1)" // "#3c4042" //"rgba(0, 76, 153, .15)"
            }} >

                <Header
                    cap_title={cap_title}
                    meme_author={meme_author} />

                <CardMedia
                    ref={imageRef}
                    component="img"
                    image={image_url}
                    alt={cap_title}
                />
                <Typography variant="h5" component="div"
                    sx={{
                        m: 1.2
                    }}>
                    {title}
                </Typography>
            </StyledCard>

            <RemixModal
                show={showModal}
                handleClose={() => { setShowModal(false) }}
                meme_id={meme_id}
                isLoggedIn={isLoggedIn}
                isOnServer={isOnServer}
            />
            <EmbedActions onRemix={(setRemixing) => {
                if (!isLoggedIn)
                    setShowModal(true)
                else
                    onRemixClick(setRemixing)

            }} />
        </Stack>
    )
}


const onClickJoinServer = ({ meme_id, setLoading, setIsPreview, setErrorMsg, setIsUnauthorized }) => {
    // join the server object plz 
    // signal to our own server, 
    const auth = getAuthData()

    console.log("Remixing:", meme_id, auth)
    // we are logged in but might not be on the server
    // we need to contact our own server to let us know it's DM time soon
    // const base_url = getBaseUrl()
    // const server_url = `${base_url}/remix/${auth.id}/${meme_id}/web`
    const server_url = getAPIPath({ path: REMIX_SESSION, session_id: meme_id, user_id: auth.id })

    setLoading(true)

    // setTimeout(() => {
    //     setLoading(false)
    //     // setIsPreview(true)
    // }, 2000)

    // now we fetch, and when done, we redirect
    fetch(server_url, { method: "POST", headers: { Authorization: `Bearer ${auth.access_token}` } })
        .then(res => {
            // setErrorMsg("Error creating remix session: " + res)
            if (res.status === 200) {
                return res.json()
            }
            else {
                // we've hit a 401, that means we aren't authorized -- force
                // a login
                if (!_.isEmpty(auth) && res.status === 401) {
                    setIsUnauthorized()
                }
                throw new Error("Error creating remix session: " + res.message)
            }
        })
        .then(res => {
            // alert("Res: " + res.status)
            if (res) {
                setLoading(false)
                setIsPreview(res)
            }
            else {
                setLoading(false)
                // setErrorMsg("Error creating remix session: " + auth.access_token)
                console.log("Error, empty response from server", res)
            }
        }).catch(err => {
            setLoading(false)
            setErrorMsg("Error creating remix session: " + err)
            console.log("Error: ", err)
        })
}


const Embed = ({ }) => {
    // going to have a few things that exist at the moment this is being written
    // 1. We should have meta and author information
    // 2. We have the meme meta information for display & potential edit
    const memeData = getMemeData()
    const author = getMemeAuthor()
    const meme_id = memeData.uid


    return (
        <SmallPadContainer sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: "8px"
        }}
            maxWidth="md">
            <Meme meme_id={meme_id} meme_data={memeData} meme_author={author} />
        </SmallPadContainer>
    )
}


export default Embed