// import logo from './logo.svg';
import './App.css';
// import 'bootstrap/scss/bootstrap.scss'
// import './scss/bs.scss'
// import './scss/dark-mode.scss'
// import './scss/basic.scss'
// import './scss/mui_style.scss'
import Home from "./routes/Home"
import Gallery from "./routes/Gallery"
import SPHome from "./routes/SPHome"
import Preview from "./routes/Preview"
import Theme from "./theme"
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import ThemeContextWrapper from './components/themeProvider';

import { Routes, Route, Link } from "react-router-dom";
import {
  // Route,
  NavLink,
  HashRouter
} from "react-router-dom";

const TitleBar = () => {
  return (<
    div className='titlebar px-3 py-2'>
    <h1 className='imdisco'>imDisco</h1>
    <h6 className='tagline'>Make &rarr; Share &rarr; Remix</h6>
  </div>)
}

function OldApp() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <TitleBar />
      <Routes>
        <Route path="/layers/:meme_id" element={<Home />} />
        <Route path="/meme/:meme_id" element={<Home />} />
        <Route path="/remix" element={<Home />} />
        <Route path="/preview/:preview_id" element={<Preview />} />



      </Routes>
      {/* <ThemeContextWrapper>
      <TitleBar />
      <Home style={{
        backgroundColor: '#e20',
        // "--bs-body-bg"
      }} />
    </ThemeContextWrapper> */}
    </ThemeProvider>

  );
}
function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Routes>
        <Route path="/remix" element={<SPHome />} />
        <Route path="/:artifact/:uuid/:layer" element={<SPHome />} />
        <Route path="/:artifact/:uuid" element={<SPHome />} />
        <Route path="/:artifact/" element={<Gallery />} />
        <Route path="/" element={<Gallery />} />
      </Routes>
    </ThemeProvider>)
}

export default App;
