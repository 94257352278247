import React from 'react';
// import Home from "../components/home"
// import { getAuthData, setMemeData, getBaseUrl, getMemeData, getMemeAuthor, setMemeAuthor } from "../utils"
import { Routes, Route, useParams, useHref, useLocation } from 'react-router-dom';
// import { useFetch } from "react-async"
import CircularProgress from '@mui/material/CircularProgress';
import TitleBar from "../components/fab_meme/titleBar"
import Gallery from "../components/gallery"
import { FullContainer } from "../components/styled/containers"

const MainEntry = ({ }) => {
    // let params = useParams();
    // we'll need to get auth data 
    return <FullContainer>
        <TitleBar />
        <Gallery />
    </FullContainer>
}

export default MainEntry