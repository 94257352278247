import React from 'react'
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        disco: {
            main: '#e20',
            color: "#FFF",
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        white: {
            main: '#FFF',
            color: "#000",
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        title: {
            main: 'hsla(0, 0%, 100%, .5)',
            color: "hsla(0, 0%, 100%, .5)",
            contrastText: 'rgba(0, 0, 0, 0.87)',
        }
    },
    status: {
    },
});

export default theme;