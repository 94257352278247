import React from 'react'
import Box from '@mui/material/Box';
import { useSnapshot } from 'valtio'
// import CssTextField from '@mui/material/CssTextField';
import Divider from '@mui/material/Divider';
import { CssTextField } from "../../styled/text"

import _ from "lodash"

const ConfigEditDisplay = ({ seed, setSeed, cfgScale, setCfgScale }) => {

    return <Box style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        margin: "10px",
    }}>
        <CssTextField
            id="text_edit_seed"
            label="Seed Value"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            // defaultValue={background_prompt}
            // ref={text_ref}
            type="number"
            step={1}
            defaultValue={seed}
            onChange={(e) => {
                let val = e.target.value
                console.log("Val:", val, typeof (val))
                if (val.includes("."))
                    val = val.split(".")[0]
                val = Math.floor(val)
                val = Math.abs(val)
                e.target.value = val
                setSeed(val)
            }}
        />
        <CssTextField
            id="text_edit_cfg_scale"
            label="CFG Scale"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            // defaultValue={background_prompt}
            // ref={text_ref}
            defaultValue={cfgScale}
            onChange={(e) => setCfgScale(e.target.value)}
        />

    </Box>

}

const ContentEditor = ({
    text, setText,
    triggerEdit,
}) => {
    return <CssTextField
        id="multiline-static"
        label="Image Content"
        // defaultValue={background_prompt}
        // ref={text_ref}
        defaultValue={text}
        onChange={(e) => setText(e.target.value)}
        fullWidth
        multiline
    />
}
const Pixel = ({ layer_data }) => {
    console.log("Pixel data:", layer_data)


    const proxy_params = layer_data.layer.layer_parameters.parameters
    // console.log("Background data:", layer_data.layer.layer_parameters)
    const snap_params = useSnapshot(proxy_params)
    // console.log("Background data:", proxy_params, " params", snap_params)
    const text_prompt = _.join(snap_params.sub_prompts.map(x => x.content), ",")
    return <Box sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        mt: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    }}>
        <ContentEditor
            text={text_prompt}
            setText={(val) => {
                console.log("Set text:", val)
            }}
            triggerEdit={() => {
            }} />
        <Divider />
        <ConfigEditDisplay
            seed={snap_params.seed}
            setSeed={(val) => { proxy_params.seed = val }}
            cfgScale={snap_params.cfg_scale}
            setCfgScale={(val) => { proxy_params.cfg_scale = val }}
        />
    </Box>


    // return <Box sx={{
    //     width: '100%',
    //     height: '100%',
    //     mt: 1,
    //     display: 'flex',
    //     alignItems: 'flex-start',
    //     justifyContent: 'center',
    // }}>
    //     <div>Layer: Pixel</div>
    // </Box>
}

export default Pixel