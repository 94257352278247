import React from 'react';
// import Home from "../components/home"
// import { getAuthData, setMemeData, getBaseUrl, getMemeData, getMemeAuthor, setMemeAuthor } from "../utils"
import { Routes, Route, useParams, useHref, useLocation } from 'react-router-dom';
// import { useFetch } from "react-async"
import CircularProgress from '@mui/material/CircularProgress';
import TitleBar from "../components/fab_meme/titleBar"
import FabMeme from "../components/fab_meme"
import RemixModal from "../components/embed/remix"
import Embed from "../components/embed"
import { FullContainer, FullStackStart } from "../components/styled/containers"
import _ from 'lodash';
import { getAuthData } from "../utils"



const getSearchParams = ({ search }) => {
    const obj = {}
    search.replace('?', '').split("&").forEach((param) => {
        const [key, value] = param.split('=')
        obj[key] = value
        return obj
    })
    return obj
}

const SelectContent = ({ artifact, uuid, location }) => {
    const [showModal, setshowModal] = React.useState(true)
    if (artifact == "preview") {
        // check if authorized or not? if not, we need to authorize via remix modal first
        const auth = getAuthData()
        if (!auth || _.isEmpty(auth) || !auth.access_token) {
            // send back with force login
            return <RemixModal
                closeButton={false}
                show={showModal}
                handleClose={() => {
                    // setShowModal(false)
                }}
                meme_id={uuid}
                isLoggedIn={false}
                isOnServer={true}
            />
        }

        return <FabMeme />
    }
    else if (artifact == "meme") {
        return <Embed />
    }
    else {
        // console.log("Locatino: ", location, "artifcat", artifact)
        if (!artifact && location.pathname.includes("/remix")) {
            const sparams = getSearchParams({ search: location.search })
            const [artifact, obj_id] = sparams["state"].split("__")
            const auth = getAuthData()
            console.log("Auth  data:", auth)
            // immediately relocation using the state variable
            // let { searchParams } = new URL(window.location)
            console.log("Search Prams:", sparams, "redirect to", artifact, " obj ", obj_id)
            window.location.href = `/${artifact}/${obj_id}?remix=true`
            return
        }

        // by default attempt embedding of whatever we look at 
        return <Embed />
    }
}

const MainEntry = ({ }) => {
    let { artifact, uuid } = useParams();
    // let href = useHref();
    let loc = useLocation();
    console.log("loc", loc, "art", artifact)
    // console.log("Params:", params)
    // console.log("Href:", href)
    // console.log("Location:", loc)
    // let {  } = useParams();
    // we'll need to get auth data 
    return <FullStackStart sx={{
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
    }}>
        <TitleBar />
        <SelectContent artifact={artifact} uuid={uuid} location={loc} />
    </FullStackStart>
}

export default MainEntry