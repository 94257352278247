import React from 'react'
import { useParams } from 'react-router-dom'
// import Container from '@mui/material/Container';
import {
    layerUrl, origLayerData, editLayerData, currentMeme,
    previewData,
    getArtifactCache, setArtifactCache, getAuthData, clearImageProxies
} from '../../store/artifact'
import { FullContainer, FullStackBetween, CenterBox } from "../styled/containers"
// import Fab from '@mui/material/Fab';
// import Box from '@mui/material/Box';
// import Loop from "@mui/icons-material/Loop"
import { useSnapshot } from 'valtio'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { StyledCard } from "../styled/cards"
import Container from '@mui/material/Container';

import Box from '@mui/material/Box';
import FabBar from './fabBar'
import SplitScreen from "./splitScreen"
import Wallpaper from "@mui/icons-material/Wallpaper"
import Portrait from "@mui/icons-material/Portrait"
import TextFormat from "@mui/icons-material/TextFormat"
import OpenWith from "@mui/icons-material/OpenWith"
import Gradient from "@mui/icons-material/Gradient"
import Filter from "@mui/icons-material/Filter"
import MobileStepper from '@mui/material/MobileStepper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import Close from "@mui/icons-material/Close"

import { useAsync } from 'react-async';
// import { getAuthData } from "../../utils"
import {
    getAPIPath, SESSION_DATA, SESSION_MEM_DATA, EDIT_SESSION,
    PUBLISH_SESSION,
    getInitRequest, getArtifactBucket
} from "../../api"

import Background from "./layers/background"
import Foreground from "./layers/foreground"
import Composite from "./layers/composite"
import TextOverlay from "./layers/textOverlay"
import Publish from "./layers/publish"
import Pixel from "./layers/pixel"
import ImageCompose from "./layers/image_compose"
// import Graph from "./layers/graph"
import { loadArtifact, getCancellableRequest } from "./load"

import CircularProgress from '@mui/material/CircularProgress';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2';
import _ from 'lodash'

import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
// import Typography from '@mui/material/Typography';
import { GradientBox, remixColors, gradient_style } from "../styled/gradients"
// import Backdrop from '@mui/material/Backdrop';



const layer_types = [
    {
        name: 'background',
        icon: () => { return <Wallpaper color="white" /> },
        select_icon: () => { return <Wallpaper color="white" /> },
        content: Background,
        // onClick: () => { saveAndProceed("background") },
        tooltip: "Background",
        stepper: "Select Background Image",
    },
    {
        name: 'foreground',
        icon: () => { return <Portrait color="white" /> },
        select_icon: () => { return <Portrait color="white" /> },
        // onClick: () => { saveAndProceed("foreground") },
        content: Foreground,
        tooltip: "Foreground",
        stepper: "Select Foreground Image",

    },
    {
        name: 'composite',
        icon: () => { return <OpenWith color="white" /> },
        select_icon: () => { return <OpenWith color="white" /> },
        // onClick: () => { saveAndProceed("composite") },
        content: Composite,
        stepper: "Arrange Images Together",
        tooltip: "Compose"
    },
    {
        name: 'text_overlay',
        icon: () => { return <TextFormat color="white" /> },
        select_icon: () => { return <TextFormat color="white" /> },
        content: TextOverlay,
        // onClick: () => { saveAndProceed("text_overlay") },
        stepper: "Select Text to Overlay",
        tooltip: "Text Overlay"
    },
    {
        name: 'pixel',
        icon: () => { return <Gradient color="white" /> },
        select_icon: () => { return <Gradient color="white" /> },
        content: Pixel,
        // onClick: () => { saveAndProceed("pixel") },
        stepper: "Add Image Effect",
        tooltip: "Pixel Effect"
    },
    {
        name: 'image_compose',
        icon: () => { return <Filter color="white" /> },
        select_icon: () => { return <Filter color="white" /> },
        content: ImageCompose,
        // onClick: () => { saveAndProceed("image_compose") },
        stepper: "Compose Image",
        tooltip: "Compose Image"
    },
]


const LayerEdit = ({ setErrorMsg, saveAndProceed, layerSaveFct, discardChanges }) => {
    const layer_data = useSnapshot(editLayerData)
    const anyChanges = layer_data.anyChanges
    // const [anyChanges, setAnyChanges] = React.useState(false)

    let layer, LayerContent, ldata, orig_data
    if (layer_data.currentLayer > layer_data.layers.length - 1) {
        layer = { stepper: "Publish Meme" }
        ldata = editLayerData
        orig_data = origLayerData
        LayerContent = Publish
    }
    else {
        layer = layer_data.layers[layer_data.currentLayer]
        ldata = editLayerData.layers[layer_data.currentLayer].layer_data
        orig_data = origLayerData.layers[layer_data.currentLayer]
        LayerContent = layer.content
    }



    return (
        <FullContainer sx={{
            position: 'relative',
            maxWidth: "md",
        }}>
            <Box sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                mt: 1,
                mb: 1,

            }}>

                <Step>
                    <StepLabel componentsProps={{ fontSize: "1.5em" }}
                        sx={{ fontSize: "1.35em" }}>
                        <Typography variant="h4" sx={{
                            fontSize: "1.45em",
                            textAlign: "center",
                        }}>
                            <span
                                // className="imdisco"
                                style={{
                                    fontSize: "1.05em"
                                }}>Step {layer_data.currentLayer + 1}: </span> {layer.stepper}
                        </Typography>
                    </StepLabel>
                </Step>
                <MobileStepper
                    sx={{ backgroundColor: 'transparent' }}
                    variant="dots"
                    steps={layer_data.layers.length + 1}
                    position="static"
                    activeStep={layer_data.currentLayer}
                    nextButton={null}
                    //     <Button size="small"
                    //         onClick={() => { }} >
                    //         Next
                    //     </Button>
                    // }
                    backButton={null}
                //     <Button size="small"
                //         onClick={() => { }} >
                //         Back
                //     </Button>
                // }
                />
                {/* <Stepper
                sx={{ mt: 1, mb: 1 }}
                activeStep={layer_data.currentLayer}
                alternativeLabel
                >
                {layer_data.layers.map((layer) => (
                    <Step key={layer.name}>
                    <StepLabel>{layer.stepper}</StepLabel>
                    </Step>
                    ))}
                </Stepper> */}
            </Box>
            <FabBar
                position={"fixed"}
                top={"auto"}
                right={5}
                bottom={8}
                left={"auto"}
                // sx={{ position: 'fixed', bottom: 0, left: 0, }}
                elevation={3}
                anyChanges={anyChanges}
                discardChanges={discardChanges}
                saveAndProceed={saveAndProceed}
                currentLayer={layer_data.currentLayer}
                layers={layer_data.layers} />
            <LayerContent
                orig_data={orig_data}
                layer_data={ldata} layer={layer} setErrorMsg={setErrorMsg}
                setAnyChanges={(val) => { editLayerData.anyChanges = val }}
                onSaveData={(fct) => [
                    layerSaveFct.current = fct
                ]} />
        </FullContainer>

    )
}

const LayerView = ({ saveAndProceed, overwriteMemeData, ...props }) => {

    // const [currentLayer, setCurrentLayer] = React.useState(0)
    const layer_snap = useSnapshot(editLayerData)
    const [metaQuery, setMetaQuery] = React.useState(null)

    // const meme = getCurrentMeme()
    // console.log("Meme: ", meme)


    let auth = getAuthData()
    let { artifact, uuid } = useParams()
    let api_img = getAPIPath({
        user_id: auth.id, path: SESSION_MEM_DATA,
        session_id: uuid, artifact: "meta", layer_type: "preview_meme"
    })
    const { data: meta_str, error, isLoading } = useAsync({
        promiseFn: loadArtifact,
        active: metaQuery,
        setActive: setMetaQuery,
        api_url: api_img,
        request: getInitRequest({ auth, method: "GET" }),
        is_json: false
    })


    React.useEffect(() => {
        if (meta_str == null)
            return
        // convert to layer info and set state in snapshot 
        const meta_author = JSON.parse(meta_str)
        const meta = meta_author.meta
        const author = meta_author.author

        console.log("meta: ", meta, "author: ", author)
        console.log("Layers", meta.meme_graph.layers)
        overwriteMemeData(meta)
        // editLayerData.layers = meta.layers

    }, [meta_str])


    return isLoading ?
        <CenterBox><CircularProgress color="white" /></CenterBox> :
        <LayerEdit
            saveAndProceed={saveAndProceed}
            {...props} />
    // currentLayer={currentLayer} layers={layers}

}


const saveEditSession = async ({ uuid, overwriteMemeData }) => {
    let meme = currentMeme.meme
    console.log("Current meme to send: ", _.cloneDeep(meme))
    let layers = editLayerData.layers
    // overwrite the meme layers
    meme.meme_graph.layers = layers.map((layer) => {
        return layer.layer_data
    })
    // JSON.parse(JSON.stringify(layers))
    console.log("New meme to send: ", _.cloneDeep(meme))
    // then let's send our meme up to the cloud
    let auth = getAuthData()
    if (auth == null || _.isEmpty(auth))
        return
    let api_url = getAPIPath({
        user_id: auth.id, path: EDIT_SESSION,
        session_id: uuid,
    })
    let request = getInitRequest({ auth, method: "POST" })
    request.body = JSON.stringify(meme)
    let is_json = true
    const ret = await getCancellableRequest(api_url, request, is_json)
    console.log("Save edit session: ", ret)
    // get current meme from here
    overwriteMemeData(ret.meta)
    return ret
}


const publishSession = async ({ uuid, title }) => {
    // JSON.parse(JSON.stringify(layers))
    // console.log("New meme to send: ", _.cloneDeep(meme))
    // then let's send our meme up to the cloud
    let auth = getAuthData()
    if (auth == null || _.isEmpty(auth))
        return
    let api_url = getAPIPath({
        user_id: auth.id, path: PUBLISH_SESSION,
        session_id: uuid,
    })
    let request = getInitRequest({ auth, method: "POST" })
    request.body = JSON.stringify({ title })
    let is_json = true
    const ret = await getCancellableRequest(api_url, request, is_json)

    if (ret && ret.published) {
        window.location.href = `/meme/${uuid}`
        return true, ret
    }

    console.log("failed publish session: ", ret)
    // get current meme from here
    // overwriteMemeData(ret.meta)
    return false, ret
}


const PublishModal = ({ isPublishing, show, handleClose, setErrorMsg }) => {

    const [title, setTitle] = React.useState("")
    return <Modal
        open={show}
        onClose={handleClose}
        key="remix_modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    // gradientColors={remixColors}
    >
        <Fade in={show}>
            <GradientBox
                gradientColors={remixColors}
                sx={gradient_style} className="imdisco-border">
                <Typography id="modal-modal-title"
                    style={{
                        margin: "5px",
                        fontWeight: 500,
                    }}
                    variant="h5" component="h2">
                    Publish Your Meme
                </Typography>
                <Typography id="modal-modal-description"
                    style={{
                        m: 1,
                        color: "#ababab",
                        width: "75%",
                        textAlign: "center",
                    }}
                    variant="body1" component="p">
                    Set your title, which is displayed when you share your meme
                </Typography>
                <TextField
                    sx={{ m: 1 }}
                    id="outlined-basic"
                    label="Meme Title"
                    variant="outlined"
                    defaultValue={title}
                    onChange={(e) => setTitle(e.target.value)}
                    multiline
                />
                <Button variant="outlined"
                    sx={{ m: 1 }}
                    disabled={isPublishing}
                    onClick={() => {
                        if (title == null || title == "") {
                            setErrorMsg("You must enter a title for your meme!")
                            return
                        }
                        handleClose(title)
                    }}>Publish!</Button>
                {isPublishing && <CircularProgress color="white" />}

            </GradientBox>
        </Fade>
    </Modal>
}


const replaceURL = ({ goto_layer, artifact, uuid }) => {
    const new_url = new URL(`${window.location.origin}/${artifact}/${uuid}/${goto_layer}`)
    const old_url = new URL(window.location)
    old_url.searchParams.forEach((value, key) => {
        // console.log("Adding param: ", key, value)
        new_url.searchParams.append(key, value)
    })
    // console.log("Location: ", new_url)
    window.history.replaceState(null, '', new_url);
    return
}
const FabMeme = ({ }) => {
    let { artifact, uuid, layer } = useParams()
    // const [errorMsg, setErrorMsg] = React.useState(null)

    // check the layers
    let layer_data = useSnapshot(editLayerData)
    // let snap_url = useSnapshot(layerUrl)
    let [saving, setSaving] = React.useState(false)

    let [resolveLayer, setResolveLayer] = React.useState(false)
    let [resolveMisLayer, setResolveMisLayer] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState(null)

    const [showPublishModal, setShowPublishModal] = React.useState(false)
    const [isPublishing, setIsPublishing] = React.useState(false)

    const handlePublishClose = (title) => {
        console.log("Publish close with title:", title)
        setIsPublishing(true)
        publishSession({ uuid: currentMeme.meme.uid, title }).then(() => {
            setShowPublishModal(false)
            setIsPublishing(false)
        }).catch(err => {
            console.log("Error publishing: ", err)
            setIsPublishing(false)
            setErrorMsg("Error publishing: " + err)
        })
    }
    // React.useEffect(() => {
    //     if (layer_data.layers.length == 0)
    //         return

    //     if (layer != null) {
    //         // if layer != our index layer 
    //         let togoIndex = _.findIndex(layer_data.layers, { name: layer })
    //         if (layer == "publish") {
    //             togoIndex = layer_data.layers.length
    //         }
    //         // error on which layer to go to sorry
    //         if (togoIndex == -1) {
    //             togoIndex = 0
    //             replaceURL({ goto_layer: layer_data.layers[togoIndex].name, artifact, uuid })
    //         }

    //         // console.log("Layer: ", layer, "index: ", togoIndex)
    //         if (togoIndex != layer_data.currentLayer) {
    //             editLayerData.currentLayer = togoIndex
    //         }
    //     }
    // }, [layer_data.layers])
    // React.useEffect(() => {
    //     if (layer_data.layers.length == 0)
    //         return
    //     if (snap_url.gotoLayerName == null) {
    //         // if the layer is null
    //         layerUrl.gotoLayerName = layer || layer_data.layers[0].layer_name
    //     }
    //     // if (layer == null) {
    //     //     console.log("LAYER: ", layer, "layers: ", layer_data.layers, "currentLayer: ", layer_data.currentLayer)
    //     //     // set the current URL
    //     //     const goto_layer = layer_data.layers[layer_data.currentLayer].name
    //     //     replaceURL({ goto_layer, artifact, uuid })
    //     //     return
    //     // }
    //     // else {
    //     //     console.log("LAYER NON NULL: ", layer)
    //     // }
    //     // otherwise we have a layer name 
    //     // check that it matches 
    //     // const cur_layer = layer_data.layers[layer_data.currentLayer].name
    //     // if (cur_layer != layer) {
    //     //     // mismatch on name, just fix the URL
    //     //     replaceURL({ cur_layer, artifact, uuid })
    //     // }
    // }, [snap_url.gotoLayerName, layer_data.layers])

    // React.useEffect(() => {
    //     // don't replace if empty!
    //     if (!snap_url.gotoLayerName)
    //         return

    //     if (snap_url.gotoLayerName != snap_url.currentLayerName) {
    //         // need to actually go to this layer plz
    //         console.error("Layer mismatch: ", snap_url.gotoLayerName, layer)
    //         replaceURL({ goto_layer: snap_url.gotoLayerName, artifact, uuid })
    //         layerUrl.currentLayerName = snap_url.gotoLayerName
    //     }
    //     else
    //     {
    //         // otherwise we're equal
    //         // just rewrite the url 
    //         if(Layer != snap_url.gotoLayerName)
    //             replaceURL({ goto_layer: snap_url.gotoLayerName, 
    //                 artifact, uuid })
    //     }

    //     if (snap_url.gotoLayerName == layer) {
    //         const toGoIndex = _.findIndex(layer_data.layers, { name: layer })
    //         if (layer_data.currentLayer != toGoIndex) {
    //             // need to switch to the matching layer info plz -- if not already
    //             editLayerData.currentLayer = toGoIndex
    //         }
    //     }

    // }, [snap_url.gotoLayerName, layer])
    // React.useEffect(() => {
    //     // check layer match
    //     if (layer_data.layers.length == 0)
    //         return
    //     // have layers
    //     if (layer == null) {
    //         // first layer 
    //         const goto_layer = layer_data.layers[0].name
    //         replaceURL({ goto_layer, artifact, uuid })
    //         setResolveLayer(true)
    //         return
    //     }
    //     if (layer == "publish") {
    //         editLayerData.currentLayer = layer_data.layers.length
    //         setResolveLayer(true)
    //         return
    //     }

    //     const toGoIndex = _.findIndex(layer_data.layers, { name: layer })
    //     if (toGoIndex != layer_data.currentLayer) {
    //         console.log("Setting layer to go to: ", toGoIndex)
    //         editLayerData.currentLayer = toGoIndex
    //     }
    //     setResolveLayer(true)
    // }, [artifact, uuid, layer, layer_data.layers])

    // React.useEffect(() => {
    //     // if currentLayer changes, check URL matches
    //     if (layer_data.layers.length == 0)
    //         return
    //     const clayer = layer_data.currentLayer >= layer_data.layers.length ?
    //         { name: "publish" } : layer_data.layers[layer_data.currentLayer]
    //     if (clayer.name != layer) {
    //         const goto_layer = clayer.name
    //         replaceURL({ goto_layer, artifact, uuid })
    //     }
    //     setResolveMisLayer(true)
    // }, [artifact, uuid, layer_data.currentLayer])


    // const saveAndProceed = () => {
    //     // TODO: Save the current state of the meme
    //     // then bump currentLayer
    //     if (layer_data.currentLayer >= layer_data.layers.length) {
    //         // TODO: Save the meme
    //         // TODO: Publish!

    //     }
    //     else {
    //         // we're ready to move to the next layer
    //         editLayerData.currentLayer += 1
    //     }
    // }
    let layerSaveFct = React.useRef(null)


    // undefined //async () => { return }
    // const setLayerSaveFct = (fct) => {
    //     layerSaveFct = fct
    // }

    // const layerClick = (layer_name) => {
    //     console.log("Switching to layer: ", layer_name, " in :", editLayerData.layers)
    //     layerSaveFct().then(() => {
    //         editLayerData.currentLayer = _.findIndex(editLayerData.layers, { name: layer_name })
    //         // setCurrentLayer(_.findIndex(layers, { name: layer_name }))
    //         console.log("Layer saved, heading to next layer", editLayerData.currentLayer)
    //     })
    // }

    const overwriteMemeData = React.useCallback((meta) => {
        currentMeme.meme = meta
        clearImageProxies()
        if (editLayerData.layers.length > 0)
            editLayerData.layers = []

        // TODO: respect meme_graph connections
        // console.log("Orig layer:", origLayerData.layers.length)
        if (origLayerData.layers.length > 0)
            origLayerData.layers = []

        meta.meme_graph.layers.map((layer) => {
            let ltype, lname
            if (layer.layer) {
                ltype = layer.layer.layer_type
                lname = layer.layer_name
            }
            else {
                ltype = layer.layer_type
                lname = layer.layer_name
            }
            let LayerDisplay = _.find(layer_types, { name: ltype })

            if (LayerDisplay == null)
                console.log("Mising layer type:", ltype)
            else {
                editLayerData.layers.push({ layer_name: lname, layer_data: layer, ...LayerDisplay })
            }
            origLayerData.layers.push(_.cloneDeep(layer))
        })
    }, [])
    const saveAndProceed = React.useCallback((layer_name = null) => {
        if (layer_name == null) {
            // console.log("Clayer:", layer_data.currentLayer, "layers:", layer_data.layers, "eln:", layer_data.layers.length)
            if (editLayerData.currentLayer >= editLayerData.layers.length) {
                // TODO: Save the meme
                // TODO: Publish!
                console.log("Publishing!")
                // open the modal
                setShowPublishModal(true)
            }
            else {
                setSaving(true)
                // we're ready to move to the next layer
                layerSaveFct.current().then((isDifferent) => {
                    // console.log("Edit meme data:", editLayerData)
                    if (isDifferent)
                        return saveEditSession({ uuid, overwriteMemeData })
                    return {}
                }).then((res) => {
                    console.log("Edit Session response: ", res)
                    const nlayer = editLayerData.currentLayer + 1
                    const goto_layer = (nlayer >= editLayerData.layers.length ?
                        "publish" : editLayerData.layers[nlayer].name)
                    // replaceURL({ goto_layer, artifact, uuid })
                    editLayerData.currentLayer = nlayer
                    editLayerData.anyChanges = false
                    setSaving(false)

                    // layerUrl.currentLayerName = goto_layer
                    console.log("Layer saved, heading to next layer", editLayerData.currentLayer)
                }).catch((err) => {
                    console.error("Error saving layer: ", err)
                })
            }
        }
        else {
            console.log("Switching to layer: ", layer_name, " in :", editLayerData.layers)
            setSaving(true)
            layerSaveFct.current().then((isDifferent) => {
                // console.log("Edit meme data:", editLayerData)
                if (isDifferent)
                    return saveEditSession({ uuid, overwriteMemeData })
                return {}
            }).then(() => {
                const nlayer = _.findIndex(editLayerData.layers, { name: layer_name })
                console.log("Desired layer :", nlayer, " alyers:", editLayerData.layers)
                const goto_layer = (nlayer >= editLayerData.layers.length ?
                    "publish" : editLayerData.layers[nlayer].name)
                // replaceURL({ goto_layer, artifact, uuid })
                editLayerData.currentLayer = nlayer
                // moving to new layer, no changes present
                editLayerData.anyChanges = false
                setSaving(false)
                // layerUrl.currentLayerName = goto_layer
                // replaceURL({ goto_layer, artifact, uuid })
                // setCurrentLayer(_.findIndex(layers, { name: layer_name }))
                console.log("Layer saved, heading to next layer", editLayerData.currentLayer)
            }).catch((err) => {
                console.error("Error saving layer during switch: ", err)
            })
        }
    }, [uuid])
    const discardChanges = React.useCallback(() => {
        // need to remove any changes that did happen inside the layer
        // oof this is complicated -- need to simplify
        console.log("Want to discard changes")
        // get the current layer
        const cindex = editLayerData.currentLayer
        // replace currently editing object
        console.log("To repalce: ", editLayerData.layers[cindex], " with ", JSON.parse(JSON.stringify(origLayerData.layers[cindex])))
        editLayerData.layers[cindex].layer_data = JSON.parse(JSON.stringify(origLayerData.layers[cindex]))
        editLayerData.layers[cindex] = _.cloneDeep(editLayerData.layers[cindex])
        editLayerData.anyChanges = false

    }, [])

    return <Box sx={{ width: "100%", height: "100%" }}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={saving}
        // onClick={handleClose}
        >
            <Box sx={{
                width: "100%", height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center", alignItems: "center"
            }} >
                <Typography variant="h4"
                    component="div" sx={{ m: 1, color: "white" }}>
                    <span className="imdisco">Saving...</span>
                </Typography>
                <CircularProgress color="inherit" />
            </Box>
        </Backdrop>
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}

            autoHideDuration={3000}
            open={errorMsg ? true : false}
            onClose={() => {
                setErrorMsg(null)
            }}
            key={"error_top_center"}
        >
            <Alert onClose={() => {
                setErrorMsg(null)
            }} severity="error" sx={{ width: '100%' }}>
                {errorMsg}
            </Alert>
        </Snackbar>
        <PublishModal
            show={showPublishModal}
            isPublishing={isPublishing}
            handleClose={handlePublishClose}
            setErrorMsg={(err) => {
                // send to error
                setErrorMsg(err)
            }}
        />
        <LayerView
            overwriteMemeData={overwriteMemeData}
            discardChanges={discardChanges}
            saveAndProceed={saveAndProceed}
            layerSaveFct={layerSaveFct}
            setErrorMsg={(err) => { setErrorMsg(err) }} />
    </Box>
    // return (true ? <SplitScreen
    //     saveAndProceed={saveAndProceed}
    //     currentLayer={layer_data.currentLayer}
    //     layers={layer_data.layers}>
    //     <PreviewImage />
    //     <LayerView />
    // </SplitScreen> : <CircularProgress color="white" />)
}

// const oldcode = () => {
//     return <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 4, md: 8 }}
//         sx={{
//             width: "100%",
//             height: "100%",
//             display: "flex",
//             alignItems: "flex-start",
//             justifyContent: "center",
//             flex: 1,
//             // overflow: "auto"
//         }}>
//         <Grid xs={1} sm={2} md={4} key={"grid_image"} sx={{
//             display: "flex",
//             // height: "100%",
//             alignItems: "center",
//             justifyContent: "center",
//             // height: "50%",
//         }}>
//             {/* <Box sx={{
//                 backgroundColor: "hsla(0, 0%, 100%, .1)",
//                 height: "200px",
//                 maxHeight: "200px"
//                 // flex: 1,
//             }}>
//                 {"Basic Attempt at shrink"}
//             </Box> */}
//             <PreviewImage />
//         </Grid>
//         <Grid xs={1} sm={2} md={4} key={"grid_layer"} sx={{
//             display: "flex",
//             flex: 1,
//             alignItems: "center",
//             justifyContent: "center",
//             // height: "50%",
//         }}>
//             <LayerEdit currentLayer={currentLayer} layers={layers} />
//         </Grid>
//     </Grid>
// }

export default FabMeme