import React from 'react'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import DeleteForever from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ContentPaste from '@mui/icons-material/ContentPaste';

import SplitScreen from "../splitScreen"
import { useSnapshot } from 'valtio'
import { isMobile, browserName } from "react-device-detect";
import { CssTextField } from "../../styled/text"

import { editLayerData, getSessionDataProxy, getAuthData } from "../../../store/artifact"
import PreviewImage from "../previewImage"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Wallpaper from "@mui/icons-material/Wallpaper"
import Portrait from "@mui/icons-material/Portrait"
import _ from 'lodash'
import { getLayerImage, createPasteEvent, convertFileToBase64 } from "../load"
import { fabric } from 'fabric';


import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'

const scaleImageWithAspectRatio = (image, width, height) => {
    const scale = Math.min(width / image.width, height / image.height)
    return scale
    // return {
    //     width: image.width * scale,
    //     height: image.height * scale
    // }
}

const offset_to_xy = ({ offset, width, height, cparams, canvas }) => {
    const imageWidth = cparams.width
    const imageHeight = cparams.height
    const offset_x = offset[0]
    const offset_y = offset[1]
    const anchor_x = Math.floor(offset_x * imageWidth)
    const anchor_y = Math.floor((1 - offset_y) * imageHeight)
    const anchor_img_x = Math.floor(offset_x * width)
    const anchor_img_y = Math.floor((1 - offset_y) * height)
    //# at init_yp == 0, anchor_y - anchor_img_y, at init_yp == 1, this is 0
    const top = anchor_y - anchor_img_y
    //# at init_xp == 0, this is 0, at init_xp == 1 this is rw - w
    const left = anchor_x - anchor_img_x

    return {
        top: canvas.height / 2 - imageHeight / 2 + top,
        left: canvas.width / 2 - imageWidth / 2 + left
    }
}

const RectImage = ({ index, image_data, editor, cparams, layer_data, redraw }) => {
    // console.log("Rect image:", image_data, " all params:", cparams)

    const imageWidth = cparams.width
    const imageHeight = cparams.height

    const irgba = image_data.color//.split("irgba::")[1]
    const img_color = irgba.split(",")
    const img_scale = image_data.scale
    const offset = image_data.offset
    const img_width = (image_data.width || imageWidth)
    const img_height = (image_data.height || imageHeight)

    const tag = image_data.img_src
    let canvas = editor.canvas
    var objects = canvas.getObjects();
    // console.log("Check for existing image objects:", objects, _.find(objects, { 'tag': tag }))
    var prevObject = _.find(objects, { 'tag': tag })

    React.useEffect(() => {
        // console.log("Check for existing for objects any match and remove: ", prevObject)
        // console.log("Comp details: ", cparams)
        if (prevObject != null) {
            canvas.remove(prevObject)
        }
        let rect_offset = offset_to_xy({
            offset,
            width: img_width * img_scale,
            height: img_height * img_scale,
            cparams, canvas
        })

        let rect = new fabric.Rect({
            tag,
            width: img_width,
            height: img_height,
            scaleX: img_scale,
            scaleY: img_scale,
            fill: `rgba(${irgba})`,
            opacity: img_color[3] / 255.0,
            hasBorders: true,
            ...rect_offset,
        })
        rect.setControlsVisibility({
            // ml: false,
            // mt: false,
            // mr: false,
            // mb: false,
        })
        canvas.insertAt(rect, index)
        // console.log("Rect created:", rect, " from image data:", JSON.parse(JSON.stringify(image_data)))

    }, [image_data, redraw])




    // editor.addRectangle({
    //     tag,
    //     width: img_width,
    //     height: img_height,
    //     stroke: `rgba(${irgba})`,
    // })
    // fabric.Rect({
    //     width: number,
    //     height: number,
    //     fill: string,
    //     stroke: string,
    //     strokeWidth: int
    // }); 



    return null
}
const SourceImage = ({ index, image_data, editor, cparams, layer_data, redraw }) => {
    const img_with_prefix = image_data.img_src.split("artifact::")[1]
    const img_name = _.last(img_with_prefix.split("/"))
    console.log("SOURCEIMAGE::Layer data:", layer_data, "looking for :", img_name)
    const img_layer = _.find(layer_data, (l) => { return l.layer_name == img_name })
    // console.log("Source image:", image_data, " all params:", cparams, "img layer:", img_layer)
    const imageWidth = cparams.width
    const imageHeight = cparams.height
    // need to throw error if img_layer is null
    // if (img_layer == null) {
    //     console.log("Error: could not find layer for image:", img_name)
    //     return null
    // }
    console.log("SOURCEIMAGE::Image layer:", img_layer)
    const img_uuid = img_layer.layer.uid || img_name
    // get our proxy object
    const img_uuid_session = getSessionDataProxy(img_uuid)

    // get the reactive version that will change if anything in proxy changes anywhere else in app
    const img_snap_session = useSnapshot(img_uuid_session)

    // now let's get the data and make sure it's added to the canvas 
    React.useEffect(() => {
        // let's get the background image
        if (img_layer.layer.uid != null) {
            getLayerImage({
                uuid: img_uuid,
                layer_type: img_layer.layer.layer_type,
                setError: (err) => { console.log("Error getting layer image", err) },
                snap_preview: img_snap_session,
                proxy_preview: img_uuid_session,
            }).then(() => {
                // console.log("fin")
            }).catch((err) => {
                console.log("Error getting layer image", err)
            })
        }
        else {
            // otherwise, we are local object for now,
            // let's set direct from data
            const limg_src = img_layer.layer.layer_parameters.parameters.img_src
            console.log("Local image:", limg_src.substring(0, 100))
            img_uuid_session.previewImage = limg_src
        }
    }, [img_snap_session.previewUrl])


    // in this effect, we react to an image preview being loaded 
    React.useEffect(() => {
        // console.log("Image data changed:", image_data)
        if (img_snap_session.previewImage != null) {
            let canvas = editor.canvas
            var objects = canvas.getObjects();
            // console.log("Check for existing image objects:", objects, _.find(objects, { 'tag': img_with_prefix }))
            var prevObject = _.find(objects, { 'tag': img_with_prefix })
            // console.log("Check for existing for objects any match and remove: ", prevObject)
            // console.log("Comp details: ", cparams)
            if (prevObject != null) {
                // console.log("To remove:", prevObject)
                canvas.remove(prevObject)
                // canvas.renderAll();
            }

            fabric.Image.fromURL(img_snap_session.previewImage, function (img) {
                // console.log("Layer scale:", image_data.scale)
                const lscale = image_data.scale || 1
                const scale = lscale * scaleImageWithAspectRatio(img, imageWidth, imageHeight)
                const width = img.width * scale
                const height = img.height * scale

                // console.log("New rect width, height", width, height)
                let rect_offset = offset_to_xy({
                    offset: image_data.offset,
                    width,
                    height,
                    cparams,
                    canvas
                })
                let lock_params = img_layer.layer.layer_type == "background" ? {
                    lockMovementX: true, lockMovementY: true,
                    lockScalingX: true, lockScalingY: true,
                    lockRotation: true,
                    hasControls: false,
                } : {}
                img.set({
                    tag: img_with_prefix,
                    ...rect_offset,
                    // left: canvas.width / 2 - width / 2,
                    // top: canvas.height / 2 - height / 2,
                    // width,
                    // height,
                    snapAngle: 5,
                    scaleX: scale,
                    scaleY: scale,
                    // lockScaling: true,
                    // lockRotation: true,
                    // lockMovementX: true,
                    // lockMovementY: true,
                    // hasControls: false,
                    ...lock_params,
                    hasBorders: true,
                });
                img.setControlsVisibility({

                    ml: false,
                    mt: false,
                    mr: false,
                    mb: false,
                })
                canvas.insertAt(img, index)
                // canvas.add(img)
                // canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), (canvas.height / img.height));
                // canvas.renderAll();
            });
        }
    }, [img_snap_session.previewImage, image_data, redraw])

    // console.log("Image name", img_name, " layer:", img_uuid, " from layer ", img_layer)
    // const back_uuid = backgroundLayer.layer.uid
    // const edit_back = getSessionDataProxy(back_uuid)
    // const snap_back = useSnapshot(edit_back)
    return null
}
const BWrap = ({ children }) => {
    return <Box sx={{ m: .5, textAlign: "center" }}>
        {children}
    </Box>
}

const FabricImage = ({ image_data, ...params }) => {
    // console.log("Fabric Image Data ", image_data)

    let ImageContent
    if (image_data.img_src.includes("artifact::")) {
        ImageContent = SourceImage
    }
    else if (image_data.img_src.includes("irgba")) {
        ImageContent = RectImage
    }

    return <ImageContent {...params} image_data={image_data} />
}



const createForegroundLayer = ({ layer_name, user_id, img_src }) => {
    return {
        layer_name,
        dependencies: [],
        layer: {
            version: '0.0.1',
            author: user_id,
            layer_type: "foreground",
            layer_parameters: {
                version: '0.0.1',
                parameters: {
                    img_src
                }
            }
        }
    }
}

const fullName = (layer_data, layer_name) => {
    return `${layer_data.layer_name}/${layer_name}`
}

// const fullLayerNames = (layer_data) => {
//     return _.map(layer_data.layers, (l) => { return fullName(layer_data, l.layer_name) })
// }

const ImageEditor = ({ orig_data, compositeLayer, layer_data, setErrorMsg, onSaveData, setAnyChanges }) => {
    const snap_layer_data = useSnapshot(layer_data)

    const [redraw, setRedraw] = React.useState(false)
    const [layers, setLayers] = React.useState([])
    const cparams = compositeLayer.layer.layer_parameters.parameters
    const [imageWidth, imageHeight] = [cparams.width, cparams.height]
    const [deleteActive, setDeleteActive] = React.useState(false)
    const cref = React.useRef(null)
    const data_diff = React.useRef(null)

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { editor, onReady } = useFabricJSEditor()

    const [editorReady, setEditorReady] = React.useState(editor != null)
    if (editor != null && !editorReady) {
        setEditorReady(true)
    }

    // console.log("Refreshed image compose")
    // onDiscardData(() => {
    //     // very hard to do for now....
    //     // i'll have to work on this -- is this a layer thing
    //     // or outside the layer?
    // })
    onSaveData(async () => {
        // we have to track down all the changes that were made
        // from the editor
        if (!editorReady) {
            return false
        }

        if (!data_diff.current) {
            // no objects were modified
            return false
        }

        // let's grab our canvas and all the objects and tags
        // the order is the composite order
        const canvas = editor.canvas
        // const can_width = canvas.width
        // const can_height = canvas.height
        const objects = canvas.getObjects()
        const tags = _.mapKeys(objects, (o) => { return o.tag })

        const cstack = cparams.composite_stack
        const ccenter = canvas.getCenter()
        const comp_width = cparams.width
        const comp_height = cparams.height

        const top_left = [ccenter.left - comp_width / 2, ccenter.top - comp_height / 2]
        // creates the correct ordering
        const clayer = _.find(layer_data.layers, (l) => { return l.layer.layer_type == "composite" })
        let nparams = clayer.layer.layer_parameters.parameters
        let new_cstack = []
        _.each(Object.keys(tags), (ltag) => {
            const meme_obj = _.find(nparams.composite_stack, (c) => { return c.img_src.includes(ltag) })
            const canvas_obj = tags[ltag]

            const owidth = canvas_obj.width * canvas_obj.scaleX
            const oheight = canvas_obj.height * canvas_obj.scaleY
            const oscale = canvas_obj.getObjectScaling()
            const obj_center = canvas_obj.getCenterPoint()
            let startWidth, startHeight
            let initial_scale = 1
            const is_artifact = meme_obj.img_src.includes("artifact::")
            if (is_artifact) {
                initial_scale = scaleImageWithAspectRatio({ width: canvas_obj.width, height: canvas_obj.height }, comp_width, comp_height)
            }
            // originally what we had scalled width/height 
            startWidth = (meme_obj.width || comp_width) * initial_scale * meme_obj.scale
            startHeight = (meme_obj.height || comp_height) * initial_scale * meme_obj.scale

            // changes in width/height have occurred
            if (Math.abs(startWidth - owidth) > 1 || Math.abs(startHeight - oheight) > 1) {
                // we will need to recalculate the scale and possible width/height
                if (meme_obj.width != null && meme_obj.height != null) {
                    // we have a known width/height and need to rescale
                    // let's put all of it into scale == 1
                    if (meme_obj.scale == 1) {
                        // assign all changes to width/height directly
                        meme_obj.width = owidth / initial_scale
                        meme_obj.height = oheight / initial_scale
                    }
                    else {
                        meme_obj.scale = 1
                        meme_obj.width = owidth / initial_scale
                        meme_obj.height = oheight / initial_scale
                        // assign all the changes to scale
                        // meme_obj.width =  (owidth / meme_obj.scale)
                        // meme_obj.height = (oheight / meme_obj.scale)
                        // meme_obj.scale = Math.min(owidth / (meme_obj.width * initial_scale), oheight / (meme_obj.height * initial_scale))
                    }
                }
                else {
                    // we have width/height determined by the comp_width/height

                    // assume comp_width  & comp_height are the object sizes
                    // we now need to scale relative to that 
                    let scaleX = owidth / (comp_width * initial_scale)
                    let scaleY = oheight / (comp_height * initial_scale)
                    console.log("Scale xy", scaleX, scaleY, "width height:", owidth, oheight, "comp width height:", comp_width, comp_height)
                    console.log("Scale type:", canvas_obj.scaleX)
                    if (is_artifact) {
                        meme_obj.scale = Math.min(
                            owidth / (canvas_obj.width * initial_scale),
                            oheight / (canvas_obj.height * initial_scale))
                    }
                    else {
                        // irgba knows how to handle scaling automatically through proxy
                        // todo: oof, this is mega confusing in this logic
                    }
                }
            }

            let offset = [
                (obj_center.x - top_left[0] - owidth / 2) / (comp_width - owidth),
                (obj_center.y - top_left[1] - oheight / 2) / (comp_height - oheight)]
            offset[1] = 1 - offset[1]

            // inverted y calculation
            if (comp_width == owidth) {
                offset[0] = .5
                // offset should be 0,0
                // meme_obj.offset = [0,0]
            }
            if (comp_height == oheight) {
                offset[1] = .5
            }
            meme_obj.offset = offset


            console.log('initial scale', initial_scale, 'fin scale', meme_obj.scale)
            // ratio scaling according to x and y
            console.log("Post apply", meme_obj)
            new_cstack.push(meme_obj)
        })
        // const comp_objs = _.map(Object.keys(tags), (ltag) => { return _.find(cstack, (c) => { return c.img_src.includes(ltag) }) })
        // _.forEach(comp_objs, (cobj) => {
        //     // get the canvas object
        //     const cobj = tags[cobj.img_src]
        // })
        console.log("tags", tags)
        console.log("Cstack", cstack)
        console.log("Composite Objects", new_cstack)
        // replace the composite stack object with the new setup
        nparams.composite_stack = new_cstack

        // update the layer parameters

        return true
    })



    React.useEffect(() => {
        if (editorReady && editor != null) {

            const canvas = editor.canvas
            console.log("Touc support:", fabric.isTouchSupported)
            fabric.isTouchSupported = true
            canvas.isTouchSupported = true
            canvas.preserveObjectStacking = true;
            canvas.uniformScaling = true;
            console.log("Canvas size:", canvas.getWidth(), canvas.getHeight())
            canvas.setDimensions({ width: canvas.getWidth(), height: canvas.getHeight() })
            console.log("Point:", canvas.getCenterPoint(), " height zoom:", canvas.getHeight() / canvas.getZoom(), "width zoom:", canvas.getWidth() / canvas.getZoom())
            const heightZoom = canvas.getHeight() / imageHeight
            const widthZoom = canvas.getWidth() / imageWidth
            canvas.zoomToPoint(
                canvas.getCenterPoint(),
                Math.min(heightZoom, widthZoom));
            canvas.on('mouse:down', function (e) {
                // console.log("Mouse down", e)
                // console.log("Mouse down not active", canvas.getActiveObject())
                if (!canvas.getActiveObject()) {
                    // console.log("Mouse down not active ob")
                    setDeleteActive(false)
                }
            });
            canvas.on('object:modified', function (e) {
                setDeleteActive(true)
                data_diff.current = true
                setAnyChanges(true)
                // console.log("Obj modified:", e)
            });

            const onSelect = (selected) => {
                let layer = _.find(snap_layer_data.layers, (l) => { return l.layer_name == selected.tag })
                if (layer == null)
                    layer = _.find(snap_layer_data.layers, (l) => { return fullName(layer_data, l.layer_name) == selected.tag })

                // console.log("Want to bring to front:", selected.tag, fullLayerNames(layer_data))
                if (layer == null || (layer && layer.layer.layer_type != "background")) {
                    // console.log("Want to bring to front", layer)
                    canvas.bringToFront(selected)
                    // canvas.sendToBack(selected)
                }
            }
            canvas.on('selection:created', function (e) {
                setDeleteActive(true)
                // console.log("Selection created", e)
                let selected
                if (e.target)
                    selected = e.target
                else if (e.selected)
                    selected = e.selected[0]
                else
                    return
                onSelect(selected)

                // if(e.target.tag.includes("artifact::")){
                // canvas.sendToBack(line);
            });
            canvas.on('selection:updated', function (e) {
                setDeleteActive(true)
                let selected
                if (e.target)
                    selected = e.target
                else if (e.selected)
                    selected = e.selected[0]
                else
                    return
                onSelect(selected)
            });
            canvas.on('selection:cleared', function (e) {
                setDeleteActive(false)
            });
            //touch zoom
            canvas.on({
                'touch:gesture': function (e) {
                    // alert("Gesture!")
                    console.log("Gesture", e)
                    if (e.e.touches && e.e.touches.length == 2) {
                        console.log("Touch gesture", e)
                        // pausePanning = true;
                        // var point = new fabric.Point(e.self.x, e.self.y);
                        // if (e.self.state == "start") {
                        // zoomStartScale = canvas.getZoom();
                        // }
                        // var delta = zoomStartScale * e.self.scale;
                        // canvas.zoomToPoint(point, delta);
                        // pausePanning = false;
                        // // limit zoom to 4x in
                        // if (delta > 4) delta = 4;
                        // // limit zoom to 1x out
                        // if (delta < 1) {
                        // delta = 1;
                        // canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
                        // }
                    }
                }
            });
        }
    }, [editorReady])

    React.useEffect(() => {
        if (editorReady) {
            setLayers(cparams.composite_stack.map((layer, index) => {
                return <FabricImage
                    key={"limage_" + index}
                    index={index}
                    redraw={redraw}
                    layer_data={snap_layer_data.layers}
                    image_data={layer}
                    cparams={cparams}
                    editor={editor}
                />
            }))
        }
    }, [editorReady, cparams, snap_layer_data, redraw])


    React.useEffect(() => {
        if (editor == null)
            return
        window.onresize = function (event) {
            const outerCanvasContainer = document.getElementsByClassName('canvas-container')[0];
            const containerWidth = outerCanvasContainer.clientWidth;
            const containerHeight = outerCanvasContainer.clientHeight;
            let canvas = editor.canvas
            const heightZoom = containerHeight / imageHeight
            const widthZoom = containerWidth / imageWidth
            canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
            canvas.setDimensions({
                width: containerWidth,
                height: containerHeight
            })
            canvas.zoomToPoint(
                new fabric.Point(containerWidth / 2, containerHeight / 2),
                Math.min(heightZoom, widthZoom));
            console.log("Resize:", containerWidth, containerHeight, imageWidth, imageHeight, widthZoom, heightZoom)
            console.log("Zoom to point:", containerWidth / 2, containerHeight / 2, Math.min(heightZoom, widthZoom))
            console.log("Zoom factor:", Math.min(heightZoom, widthZoom))
            canvas.renderAll()
            setRedraw(!redraw)


            // const canvas = editor?.canvas

            // const ratio = canvas.getWidth() / canvas.getHeight();
            // const scale = containerWidth / canvas.getWidth();
            // const zoom = canvas.getZoom() * scale;

            // canvas.setDimensions({ width: containerWidth, height: containerWidth / ratio });
            // canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
            // console.log("Canvas dims:", canvas.width, canvas.height)
            // console.log("Canvas zoom scale", zoom, scale)
        }
    }, [editor])
    const onPaste = (e) => {
    }
    const onImageData = (data) => {
        if (data == null) {
            return
        }
        console.log("paste image data retrieved", data.substring(0, 100))
        const auth = getAuthData()
        const lname = "paste_" + Date.now()
        const foreground = createForegroundLayer({
            user_id: auth.id,
            layer_name: lname,
            img_src: data
        })
        // console.log("Foreground layer", foreground)
        const clayer = _.find(layer_data.layers, (l) => { return l.layer.layer_type == "composite" })
        const nparams = clayer.layer.layer_parameters.parameters
        layer_data.layers.push(foreground)
        nparams.composite_stack.push({
            img_src: `artifact::${layer_data.layer_name}/${lname}`,
            scale: .5,
            offset: [.5, .5]
        })
        // create new foreground layer
        // add foreground layer to layer data

    }
    // const setErrorMsg = (msg) => {
    //     console.log("Isseu pasting")
    // }

    return <Box sx={{
        width: '100%',
        height: '100%',
        mt: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    }}>
        <Box sx={{
            width: "100%",
            height: "100%",
            maxHeight: "70%",
            position: "relative",
        }}
            ref={cref}
        >

            <FabricJSCanvas
                style={{ width: "100%", height: "100%", background: "#ABC" }}

                className="fabric-canvas"
                onResize={(e) => {
                    console.log("Resize Canvas", e)
                }}
                onReady={onReady} />
            {layers}
        </Box>
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            mt: 1,
            flex: 1,
            position: "relative",
        }}
            onClick={(e) => {
                // deleted an object! differences exist
                if (editor.canvas.getActiveObject() != null) {
                    data_diff.current = true
                    setAnyChanges(true)
                    editor.canvas.discardActiveObject().renderAll();
                }
            }}
        >
            {/* action bar with a bunch of edit functions */}
            <BWrap>
                <Button

                    disabled={!deleteActive}
                    component="label"
                    variant="outlined"
                    startIcon={<DeleteForever size="large" />}
                    color="error"
                    className="fab_delete"
                    onClick={() => {
                        const active = editor.canvas.getActiveObject()
                        if (active != null) {
                            editor.canvas.remove(active)
                        }
                    }}>Delete</Button>
            </BWrap>
            <BWrap>
                <Button variant="outlined" startIcon={<PhotoCamera />} component="label">
                    Upload
                    <input hidden accept="image/*" type="file" onChange={(e) => {
                        // console.log("Updload file", e)
                        if (e.target.files && e.target.files.length > 0) {
                            convertFileToBase64({ file: e.target.files[0], onImageData })
                        }
                    }} />
                </Button>
            </BWrap>
            <BWrap>{!isMobile ? <CssTextField
                // focused={focus}
                inputProps={{ accept: "*", onPaste: onPaste, padding: 0, margin: 0 }} size="small" defaultValue={""} label="Paste Image Here" />
                : <Button variant="outlined"
                    startIcon={<ContentPaste />}
                    onClick={() => {
                        createPasteEvent(onImageData, setErrorMsg)
                    }}>Paste</Button>}
            </BWrap>
            {/* <Tabs value={value} onChange={handleChange} aria-label="icon label tabs example">
                <Tab icon={<Portrait />} label="FOREGROUND" />
                <Tab icon={<Wallpaper />} label="BACKGROUND" />
            </Tabs> */}

            {/* <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button>Foreground</Button>
                <Button>Background</Button>
                <Button>Style</Button>
            </ButtonGroup> */}
            {/* <div>Image Compose</div> */}
        </Box>
        {/* <SplitScreen > */}
        {/* <PreviewImage /> */}
        {/* <div>Layer: ImageEditor</div> */}
        {/* </SplitScreen> */}
    </Box >
}

export default ImageEditor