import { getArtifactCache, setArtifactCache } from '../../store/artifact'
import { getAuthData } from "../../store/artifact"
import { getAPIPath, getArtifactBucket, getInitRequest, SESSION_DATA } from "../../api"
import Resizer from "react-image-file-resizer";
import _ from "lodash"

const convertFileToBase64 = ({ file, onImageData }) => {
    Resizer.imageFileResizer(
        file,
        512, // Is the maxWidth of the resized new image.
        512, // Is the maxHeight of the resized new image.
        'PNG', // Is the compressFormat of the resized new image.
        100, // Is the quality of the resized new image.
        0, // Is the degree of clockwise rotation to apply to uploaded image.
        (uri) => {
            // console.log("Resized image:", uri)
            onImageData(uri)
        }, // Is the callBack function of the resized new image URI.
        "base64", // Is the output type of the resized new image.
        200, // Is the minWidth of the resized new image.
        200 // Is the minHeight of the resized new image.
    )
}
const pasteToImage = ({ event, onImageData, items }) => {
    var items = items || (event.clipboardData || event.originalEvent.clipboardData).items;
    var isData = false
    for (var index in items) {
        var item = items[index];
        if (item.kind === 'file') {
            Resizer.imageFileResizer(
                item.getAsFile(),
                512, // Is the maxWidth of the resized new image.
                512, // Is the maxHeight of the resized new image.
                'PNG', // Is the compressFormat of the resized new image.
                100, // Is the quality of the resized new image.
                0, // Is the degree of clockwise rotation to apply to uploaded image.
                (uri) => {
                    // console.log("Resized image:", uri)
                    onImageData(uri)
                }, // Is the callBack function of the resized new image URI.
                "base64", // Is the output type of the resized new image.
                200, // Is the minWidth of the resized new image.
                200 // Is the minHeight of the resized new image.
            )
        }
    }
    if (!isData) {
        onImageData(null)
    }
}

function createPasteEvent(onImageData, setErrorMsg) {
    // var pasteTarget = document.createElement("div");
    // pasteTarget.contentEditable = true;
    var prevActive = document.activeElement

    // add the event listerenr 
    const pasteHandler = (event) => {
        pasteToImage({ event, onImageData })
    }
    prevActive.addEventListener("paste", pasteHandler);
    if (document.execCommand("Paste", null, null)) {

    }
    else {
        setErrorMsg("Paste failed! Please try again.")
    }

    // remove the event listener
    prevActive.removeEventListener("paste", pasteHandler);
};


function getCancellableRequest(api_url, request, is_json = true) {
    const controller = new AbortController();
    const signal = controller.signal;
    console.log("Request: ", request)
    const promise = new Promise(async (resolve) => {
        const req = { ...request, signal }
        const response = await fetch(api_url, req)
        console.log("API response from: ", api_url, " status: ", response.status)
        if (response.status != 200) {
            throw new Error(response.statusText)
        }
        if (is_json) {
            resolve(await response.json())
        }
        else {
            resolve(await response.text())
        }
    });
    promise.cancel = () => {
        console.log("Cancelling query to: ", api_url)
        controller.abort()
    };
    return promise;
}

const loadArtifact = async ({ api_url, request, active, setActive, is_json = true }) => {
    // does the query exist already? if so, cancel it

    console.log("Requesting: ", api_url)
    // TODO: stop making multiple requests for the same damn thing 
    // subscribe to the request response instead. 
    let res = getArtifactCache(api_url)

    if (res == null && active != null) {
        if (active.cancel)
            active.cancel()
        else
            console.log("ACtive want to cancel:", active.substring(0, 100))

    }

    const promise = new Promise(async (resolve, reject) => {
        if (res != null) {
            setActive(null)
            resolve(res)
            return
        }
        const query = getCancellableRequest(api_url, request, is_json)
        setActive(query)
        query.then((to_ret) => {
            setArtifactCache(api_url, to_ret)
            // console.log("Artifact return: ", to_ret)
            resolve(to_ret)
        })
            .catch((err) => {
                // console.log("Error loading artifact", err)
                reject(err)
            })
    })

    return promise

    // to_ret = await query
    // console.log("to ret:", to_ret)
    // setArtifactCache(api_url, to_ret)
    // return to_ret
    // res = await fetch(api_url, request)
    // if (res.status != 200) {
    //     throw new Error(res.statusText)
    // }
    // if (!res.ok) throw new Error(res.statusText)
    // // body to text 
    // // return await res.body.getReader().read()
    // // // console.log("Response", await res.text())
    // let to_ret
    // if (is_json)
    //     to_ret = await res.json()
    // else
    //     to_ret = await res.text()
    // setArtifactCache(api_url, to_ret)
    // return to_ret
}

const getImageUrl = ({ auth, uuid, layer_type, no_cache }) => {
    return getAPIPath({
        user_id: auth.id, path: SESSION_DATA,
        bucket: getArtifactBucket(),
        session_id: uuid, artifact: "image", layer_type
    })
}
const getLayerImage = async ({ uuid, setError, layer_type, snap_preview, proxy_preview, no_cache = true }) => {
    let auth = getAuthData()
    if (auth == null || _.isEmpty(auth))
        return

    if (snap_preview.previewUrl == null) {
        proxy_preview.previewUrl = getImageUrl({ auth, no_cache, layer_type, uuid })
    }
    // else {
    //     if (api_img != proxy_preview.previewUrl) {
    //         proxy_preview.previewUrl = api_img
    //     }
    // }

    if (snap_preview.previewCurrentUrl != proxy_preview.previewUrl) {
        console.log("Attempt layer fetch:", proxy_preview.previewUrl)
        const ret = await loadArtifact({
            active: snap_preview.imageQuery,
            setActive: (query) => { proxy_preview.imageQuery = query },
            api_url: proxy_preview.previewUrl,
            request: getInitRequest({ auth, method: "GET" }),
            is_json: false
        }).then((img_src) => {
            console.log("Img src resolve:", img_src)
            const b64_img_src = _.isString(img_src) ? `data:image/png;base64,${img_src}` : null
            proxy_preview.previewImage = b64_img_src
            proxy_preview.previewCurrentUrl = snap_preview.previewUrl
        }).catch((err) => {
            console.log("Error retrieving image", err)
            setError(err)
            // throw err
        })
    }


}


export {
    loadArtifact,
    getCancellableRequest,
    getLayerImage,
    getImageUrl,
    pasteToImage,
    convertFileToBase64,
    createPasteEvent
}