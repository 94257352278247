import React from 'react'
import Box from '@mui/material/Box';
// import { useSnapshot } from 'valtio'
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import { CssTextField } from "../../styled/text"

// roughly remaining imports are for support layer image preview
import CircularProgress from '@mui/material/CircularProgress';
import SplitScreen from "../splitScreen"
import { getLayerImage, getImageUrl } from "../load"
import { proxy, useSnapshot } from 'valtio'
import { getSessionDataProxy, getAuthData } from "../../../store/artifact"
import _ from "lodash"

const ConfigEditDisplay = ({ seed, setSeed, cfgScale, setCfgScale }) => {

    return <Box style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        margin: "10px",
    }}>
        <CssTextField
            id="text_edit_seed"
            label="Seed Value"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            // defaultValue={background_prompt}
            // ref={text_ref}
            type="number"
            step={1}
            defaultValue={seed}
            onChange={(e) => {
                let val = e.target.value
                console.log("Val:", val, typeof (val))
                if (val.includes("."))
                    val = val.split(".")[0]
                val = Math.floor(val)
                val = Math.abs(val)
                e.target.value = val
                setSeed(val)
            }}
        />
        <CssTextField
            id="text_edit_cfg_scale"
            label="CFG Scale"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            // defaultValue={background_prompt}
            // ref={text_ref}
            defaultValue={cfgScale}
            onChange={(e) => setCfgScale(e.target.value)}
        />

    </Box>

}

const ContentEditor = ({
    text, setText,
    triggerEdit,
}) => {
    return <CssTextField
        id="multiline-static"
        label="Image Content"
        // defaultValue={background_prompt}
        // ref={text_ref}
        defaultValue={text}
        onChange={(e) => setText(e.target.value)}
        fullWidth
        multiline
    />
}


const LayerPreviewImage = ({ layer_data }) => {
    const img_uuid = layer_data.layer.uid
    // get our proxy object
    const img_uuid_session = getSessionDataProxy(img_uuid)

    // get the reactive version that will change if anything in proxy changes anywhere else in app
    const img_snap_session = useSnapshot(img_uuid_session)

    // now let's get the data and make sure it's added to the canvas 
    React.useEffect(() => {
        // let's get the background image
        if (layer_data.layer.uid != null) {
            getLayerImage({
                uuid: img_uuid,
                layer_type: layer_data.layer.layer_type,
                setError: (err) => { console.log("Error getting layer image", err) },
                snap_preview: img_snap_session,
                proxy_preview: img_uuid_session,
            }).then(() => {
                // console.log("fin")
            }).catch((err) => {
                console.log("Error getting layer image", err)
            })
        }
        // else {
        //     // otherwise, we are local object for now,
        //     // let's set direct from data
        //     const limg_src = layer_data.layer.layer_parameters.parameters.img_src
        //     console.log("Local image:", limg_src.substring(0, 100))
        //     img_uuid_session.previewImage = limg_src
        // }
    }, [img_snap_session.previewUrl])

    React.useEffect(() => {
        // when layer data changes, updated previewUrl
        let api_img = getImageUrl({
            auth: getAuthData(),
            uuid: img_uuid,
            layer_type: layer_data.layer.layer_type
        })
        img_uuid_session.previewUrl = api_img
    }, [layer_data])


    React.useEffect(() => {
        // console.log("Image data changed:", image_data)
        if (img_snap_session.previewImage != null) {
            // set it inside of our image preview plz
        }
    }, [img_snap_session.previewImage])


    return img_snap_session.previewImage == null ?
        <CircularProgress /> :
        <img
            className={"fleximg"}
            src={img_snap_session.previewImage}
            alt="meme"
            onClick={() => { }}
            style={{
            }} />

}


const Background = ({ orig_data, layer_data, onSaveData, setAnyChanges }) => {
    const proxy_params = layer_data.layer.layer_parameters.parameters
    // console.log("Background data:", layer_data.layer.layer_parameters)
    const snap_params = useSnapshot(proxy_params)
    // console.log("Background data:", proxy_params, " params", snap_params)
    const text_prompt = _.join(snap_params.sub_prompts.map(x => x.content), ",")

    onSaveData(async () => {
        const oparams = orig_data.layer.layer_parameters.parameters
        const orig_prompt = _.join(oparams.sub_prompts.map(x => x.content), ",")
        let data_diff = false
        if (text_prompt != orig_prompt) {
            data_diff = true
        }
        if (proxy_params.seed != oparams.seed) {
            data_diff = true
        }
        if (proxy_params.cfg_scale != oparams.cfg_scale) {
            data_diff = true
        }

        return data_diff
    })


    return <SplitScreen top_min_height="30%" top_height="50%">
        <LayerPreviewImage layer_data={layer_data} />
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            mt: 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        }}>
            <ContentEditor
                text={text_prompt}
                setText={(val) => {
                    setAnyChanges(true)
                    // console.log("Set text:", val)
                    if (snap_params.sub_prompts.length > 1) {
                        // we have multiple prompts, need to decide how to edit this thing
                        console.error("Can't handle multi prompt edit yet")
                    }
                    else {
                        proxy_params.sub_prompts[0].content = val
                    }
                }}
                triggerEdit={() => {
                }} />
            <Divider />
            <ConfigEditDisplay
                seed={snap_params.seed}
                setSeed={(val) => { proxy_params.seed = val }}
                cfgScale={snap_params.cfg_scale}
                setCfgScale={(val) => { proxy_params.cfg_scale = val }}
            />
        </Box>
    </SplitScreen>
}

export default Background