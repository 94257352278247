import React from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

const Gallery = ({ }) => {
    let { artifact } = useParams()
    console.log("Params:", useParams())

    return (
        <div>Gallery {artifact ? _.capitalize(artifact) : "Basic"}</div>
    )
}


export default Gallery